import * as React from "react";
import { withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import nameof from "ts-nameof.macro";
import { RouteHelper } from "../IntranetRouter";
import { CargaListItem, CargaListItemPaginatedList, CargasSearch } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { I18n } from "../utils/I18n";
import { CargaFicherosService } from "./CargaFicherosService";





class IndexComp extends IndexPageBase<CargasSearch, CargaListItemPaginatedList, CargaListItem>
{


    protected _renderSearchForm(): React.ReactNode {
        return <React.Fragment>
            <Col xs="12" md="6" lg="3">

            </Col>


        </React.Fragment>;
    }

    
    protected _getApiService(): IServiceForIndex<CargasSearch, CargaListItemPaginatedList> {
        return new CargaFicherosService();
    }

    protected _getTableColumns(): TableColumn<CargaListItem>[] {



        var columns = [
            {
                fieldName: nameof(this.state.searchResults.items[0].fecha),
                title: I18n.Strings.cargaFicheros.fecha,
                renderField: (item: CargaListItem) => I18n.Strings.formatDate(item.fecha),
            },

            {
                fieldName: nameof(this.state.searchResults.items[0].fecha),
                title: I18n.Strings.cargaFicheros.tipoCarga,
                renderField: (item: CargaListItem) => {
                    return I18n.Strings.getEnumText(`TipoCarga.${item.tipoCarga}`)
                },
            },
        ];

        return columns;
    }

    protected _getPageTitle(): string {
        return null;
    }
    protected _getPageDescription(): string {
        return null;
    }

    protected _getEditionItemUrl(item: CargaListItem): string {
        return RouteHelper.detailCargaFicheros(item.id);
    }

    protected _getNewItemUrl(): string {
        return null;
    }


}
export const Listing = withRouter(IndexComp);