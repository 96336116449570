import { stat } from "fs";
import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import nameof from "ts-nameof.macro";
import { RouteHelper } from "../IntranetRouter";
import { LicenciaContenidosEditTabs } from "../licenciasColegio/Edit";
import { LineaPedidoColegioListItem, PedidoColegioDetailResult } from "../models/dataModels";
import { AlertsService } from "../utils/AlertsService";
import { IServiceForEdit } from "../utils/base/BaseServices";
import { NavigationHelper } from "../utils/base/Breadcrumb";
import { EditPageBase } from "../utils/base/EditPageBase";
import { GenericEditState } from "../utils/base/GenericEditState";
import { ModelValidation } from "../utils/base/ModelValidation";
import { I18n } from "../utils/I18n";
import { UserIcon, ViewIcon } from "../utils/Icons";
import { PedidosColegioService } from "./PedidosColegioService";


interface PedidoColegioDetailResultEx extends PedidoColegioDetailResult {
    albaranShowDetails: number;
}

export class EditComp extends EditPageBase<PedidoColegioDetailResultEx> {

    protected _setPageTitleForNew(): string {
        return "N/A"
    }

    protected _setPageTitleForExisting(editModel: PedidoColegioDetailResultEx): string {
        return `Ver pedido ${editModel.nombre}`;
    }

    protected _createApiService(): IServiceForEdit<PedidoColegioDetailResultEx> {
        return new PedidosColegioService() as any as IServiceForEdit<PedidoColegioDetailResultEx>;
    }

    protected _generateForm(): JSX.Element {
        return <React.Fragment>
            <Row>

                <Col md="4">
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.nombre)}>
                            {I18n.Strings.pedidosColegio.nombre}
                        </Label>

                        <Input
                            readOnly={true}
                            id={nameof(this.state.editModel.nombre)}
                            value={this.state.editModel.nombre}
                        />
                    </FormGroup>
                </Col>


                <Col md={3}>

                    <FormGroup>
                        <Label for={nameof(this.state.editModel.fechaPresupuesto)}>
                            {I18n.Strings.pedidosColegio.fechaPresupuesto}
                        </Label>

                        <Input
                            readOnly={true}
                            id={nameof(this.state.editModel.fechaPresupuesto)}
                            value={I18n.Strings.formatDate(this.state.editModel.fechaPresupuesto)}
                        />
                    </FormGroup>

                </Col>


            </Row>

            {this._pintarTablaLineas(
                this.state.editModel.items,
                I18n.Strings.pedidosColegio.lineasPedido)
            }

        </React.Fragment>;
    }

    private calculaClaseNumUnidadesEnFinal(item: LineaPedidoColegioListItem): string {

        if (item.numUnidadesFinalizadas == 0 && item.numUnidades != 0) {
            return "text-danger";
        }
        if (item.numUnidadesFinalizadas == item.numUnidades) {
            return "text-success";
        }

        return "text-warning";
    }

    private _pintarTablaLineas(items: LineaPedidoColegioListItem[], titulo: string): JSX.Element {

        var albaranSeleccionado = this.state.editModel.albaranShowDetails == null
            ? null
            : this.state.editModel.albaranes.find(a => a.id == this.state.editModel.albaranShowDetails);

        return <>
            <h4 className="mt-4">{titulo}</h4>
            {items.length == 0 &&
                <React.Fragment>
                    No hay elementos
                </React.Fragment>
            }
            {items.length != 0 &&
                <table className="table table-striped table-hover table-sm">
                    <thead>

                        <tr>
                            <th>{I18n.Strings.pedidosColegio.isbn}</th>
                            <th>{I18n.Strings.pedidosColegio.producto}</th>
                            <th>{I18n.Strings.pedidosColegio.editorial}</th>
                            <th>{I18n.Strings.pedidosColegio.numUnidades}</th>
                            <th>{I18n.Strings.pedidosColegio.numUnidadesReservadas}</th>
                            <th>{I18n.Strings.pedidosColegio.numUnidadesEnPrep}</th>
                            <th>{I18n.Strings.pedidosColegio.numUnidadesEnFinal}</th>
                        </tr>

                    </thead>
                    <tbody>
                        {items.map((item, index) => {

                            return <tr key={index}>
                                <td>{item.referenciaInterna}</td>
                                <td>{item.producto}</td>
                                <td>{item.editorial}</td>
                                <td>{item.numUnidades}</td>
                                <td>{item.numUnidadesReservadas}</td>
                                <td>{item.numUnidadesPreparacion}</td>
                                <td className={this.calculaClaseNumUnidadesEnFinal(item)}>
                                    {item.numUnidadesFinalizadas}
                                </td>
                            </tr>;
                        })}
                    </tbody>
                </table>
            }

            <h3>{I18n.Strings.pedidosColegio.envios}</h3>
            {(this.state.editModel.albaranes || []).length == 0 &&
                <p className="lead text-center">No hay envíos</p>
            }
            {(this.state.editModel.albaranes || []).length > 0 &&
                <table className="table table-striped table-hover table-sm">
                    <thead>
                        <tr>
                            <th>
                                {I18n.Strings.pedidosColegio.nombre}
                            </th>

                            <th>
                                {I18n.Strings.pedidosColegio.transportista}
                            </th>
                            <th>
                                {I18n.Strings.pedidosColegio.numSeguimiento}
                            </th>
                            <th>
                                Fecha Expedición
                            </th>
                            <th>
                                Estado envío
                            </th>
                            {/*
                            <th>
                                {I18n.Strings.pedidosColegio.estado}
                            </th>
                            */ }
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.editModel.albaranes.map((albaran, index) => {

                            return <tr key={index}>
                                <td>
                                    <span title={albaran.nombre}>
                                        {albaran.documentoOrigen}
                                    </span>
                                    {' '}
                                    <ViewIcon
                                        onClick={() => {
                                            var state = this._cloneStateForSetState();
                                            state.editModel.albaranShowDetails = albaran.id;
                                            this.setState(state);
                                        }}
                                    />
                                </td>
                                <td>{I18n.Strings.getEnumText(`Transportista.${albaran.transportista}`)}</td>
                                <td>
                                    {
                                        (albaran.referenciaSeguimiento || "").length == 0
                                            ? <span>N/A</span>
                                            : <a target="_blank" href={`https://s.correosexpress.com/search?s=${albaran.referenciaSeguimiento}`}>
                                                {albaran.referenciaSeguimiento}
                                            </a>
                                    }
                                </td>
                                <td>{I18n.Strings.formatDate(albaran.fechaGeneracionEtiqueta)}</td>
                                <td>{I18n.Strings.getEnumText(`EstadoEnvio.${albaran.estadoEnvio}`)}</td>
                                {/*
                                <td>{I18n.Strings.getEnumText(`EstadoAlbaran.${albaran.estado}`)}</td>
                                */ }
                            </tr>;

                        })}
                    </tbody>
                </table>
            }

            {albaranSeleccionado && <Modal
                size="xl"
                isOpen={true}
                aria-hidden={false}
                backdrop={true}
                toggle={() => {
                    var state = this._cloneStateForSetState();

                    state.editModel.albaranShowDetails = null;
                    this.setState(state);
                }}
            >
                <ModalHeader
                    close={<button className="btn-close" onClick={() => {
                        var state = this._cloneStateForSetState();

                        state.editModel.albaranShowDetails = null;
                        this.setState(state);
                    }} ></button>}
                >
                    Detalle de envío - {albaranSeleccionado.documentoOrigen} - {albaranSeleccionado.nombre}
                </ModalHeader>
                <ModalBody>

                    <div className="row">
                        <div className="col-md-6">
                            <FormGroup>
                                <Label for={nameof(albaranSeleccionado.destinatario)}>
                                    {I18n.Strings.pedidosColegio.destinatario}
                                </Label>
                                <Input
                                    id={nameof(albaranSeleccionado.destinatario)}
                                    readOnly={true}
                                    value={albaranSeleccionado.destinatario}
                                />
                            </FormGroup>
                        </div>

                        <div className="col-md-6">
                            <FormGroup>
                                <Label for={nameof(albaranSeleccionado.compania)}>
                                    {I18n.Strings.pedidosColegio.compannia}
                                </Label>
                                <Input
                                    id={nameof(albaranSeleccionado.compania)}
                                    readOnly={true}
                                    value={albaranSeleccionado.compania}
                                />
                            </FormGroup>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <FormGroup>
                                <Label for={nameof(albaranSeleccionado.calle)}>
                                    {I18n.Strings.pedidosColegio.calle}
                                </Label>
                                <Input
                                    id={nameof(albaranSeleccionado.calle)}
                                    readOnly={true}
                                    value={albaranSeleccionado.calle}
                                />
                            </FormGroup>
                        </div>

                        <div className="col-md-6">
                            <FormGroup>
                                <Label for={nameof(albaranSeleccionado.calle2)}>
                                    {I18n.Strings.pedidosColegio.calle2}
                                </Label>
                                <Input
                                    id={nameof(albaranSeleccionado.calle2)}
                                    readOnly={true}
                                    value={albaranSeleccionado.calle2}
                                />
                            </FormGroup>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <FormGroup>
                                <Label for={nameof(albaranSeleccionado.city)}>
                                    {I18n.Strings.pedidosColegio.ciudad}
                                </Label>
                                <Input
                                    id={nameof(albaranSeleccionado.city)}
                                    readOnly={true}
                                    value={albaranSeleccionado.city}
                                />
                            </FormGroup>
                        </div>

                        <div className="col-md-4">
                            <FormGroup>
                                <Label for={nameof(albaranSeleccionado.zip)}>
                                    {I18n.Strings.pedidosColegio.codPostal}
                                </Label>
                                <Input
                                    id={nameof(albaranSeleccionado.zip)}
                                    readOnly={true}
                                    value={albaranSeleccionado.zip}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-md-4">
                            <FormGroup>
                                <Label for={nameof(albaranSeleccionado.countryId)}>
                                    {I18n.Strings.pedidosColegio.pais}
                                </Label>
                                <Input
                                    id={nameof(albaranSeleccionado.countryId)}
                                    readOnly={true}
                                    value={albaranSeleccionado.countryId}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <hr />
                   
                    <table className="table table-striped table-hover table-sm">
                        <thead>
                            <tr>
                                <th>{I18n.Strings.pedidosColegio.isbn}</th>
                                <th>{I18n.Strings.pedidosColegio.producto}</th>
                                <th>{I18n.Strings.pedidosColegio.editorial}</th>
                                <th>{I18n.Strings.pedidosColegio.numUnidades}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {albaranSeleccionado.lineas.map((itemA, index) => {

                                var itemEnPedido = this.state.editModel.items.find(p => p.productoId == itemA.productoId);

                                return <tr>
                                    <td>
                                        {itemEnPedido.referenciaInterna}
                                    </td>
                                    <td>
                                        {itemEnPedido.producto}
                                    </td>
                                    <td>
                                        {itemEnPedido.editorial}
                                    </td>
                                    <td>
                                        {itemA.numUnidades}
                                    </td>
                                </tr>;
                            })}
                        </tbody>
                    </table>
                </ModalBody>
            </Modal >}
        </>;
    }



    protected updateBreadCrumb(model: PedidoColegioDetailResultEx) {
        var items = [
            {
                text: I18n.Strings.pedidosColegio.title,
                link: RouteHelper.listPedidosColegio()
            },
            {
                text: this._setPageTitleForExisting(model),
                link: null
            }
        ];

        NavigationHelper.setBreadcrumbItems(items);

    }

    protected _getEditUrl(id: number): string {
        return RouteHelper.detallePedidoColegio(id);
    }

    protected _getListUrl(): string {
        return RouteHelper.listPedidosColegio();
    }

    protected _createStateForNew(): Promise<GenericEditState<PedidoColegioDetailResultEx>> {


        return null;
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        return validation;
    }
}

export const Edit = withRouter(EditComp);