import * as React from "react";
import nameof from "ts-nameof.macro";

import { Row, Col, FormGroup, Label, Input, Button, ModalHeader, Modal, ModalBody, ModalFooter, FormFeedback } from "reactstrap";

import { ContenidosColegioService } from "./ContenidosColegioService";
import { I18n } from "../../utils/I18n";
import { Editorial, ContenidosDigitalesColegioListItem, ContenidosDigitalesColegioListItemPaginatedList,  TipoUsuario, AccedePlataforma, TipoAcceso, YesNo, ContenidosDigitalesColegioSearch } from "../../models/dataModels";
import { IServiceForIndex } from "../../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../../utils/base/IndexPageBase";
import { RouteHelper } from "../../IntranetRouter";
import { AccedePlataformaDropDown, EditorialDropDown, TodosTipoAccesoDropDown, YesNoDropDown } from "../../helpers/EnumDropDown";
import { SyncIcon, SaveIcon, CheckIcon, ViewIcon } from "../../utils/Icons";
import { AlertsService } from "../../utils/AlertsService";
import { CursoEscolarDropDown } from "../../cursoEscolar/CursoEscolarDropDown";
import { ClientContext } from "../../utils/ClientContext";
import { NivelEducativoDropDown } from "../../cursoEscolar/NivelEducativoDropDown";
import { ModelValidation } from "../../utils/base/ModelValidation";


interface ContenidosSearchEx extends ContenidosDigitalesColegioSearch {
    cursoEscolar: string;
    nivelEducativo: string;
    
    cambiarTipoAccesoMostrarModal: boolean;
    cambiarTipoAccesoValidationResult: ModelValidation;
    cambiarTipoAccesoMostrarAccesoDirecto: boolean;
    cambiarTipoAccesoAccedePlataforma: AccedePlataforma;
    cambiarTipoAccesoTipoAccesoPorDefecto: TipoAcceso;
    cambiarTipoAccesoMensajeAcceso: string;


}

export class IndexContenidoColegio extends IndexPageBase<ContenidosSearchEx, ContenidosDigitalesColegioListItemPaginatedList, ContenidosDigitalesColegioListItem>
{
    _getInitialSearchParams(): ContenidosSearchEx {


        var cursoEscolar = ClientContext.getCursoEscolarActivo();

        var searchParams = {
            mostrarNivelesEducativos: true,
            showOnlySchoolContents: true,
            cursoEscolarId: cursoEscolar.id,
            cursoEscolar: cursoEscolar.nombre,
            showAccessData: true,
            cambiarTipoAccesoValidationResult: new ModelValidation()
        } as ContenidosSearchEx;

        return searchParams;
    }

    protected _showCheckboxes(): boolean {
        return true;
    }

    private getIdContenidosChecked() {
        var checkedElements = this.state.checkedElements;
        if (this.state.searchResults != null && this.state.searchResults.items != null) {
            var contenidosChecked = this.state.searchResults.items.filter(function (el) {
                return checkedElements.find(x => x === el.id) != null
            });
        }

        return contenidosChecked;
    }


    protected _renderModalCambiarTipoAcceso(): JSX.Element {
        var contenidosChecked = this.getIdContenidosChecked();


        return <Modal
            title={I18n.Strings.contenidos.cambiarTipoAcceso}
            isOpen={this.state.searchParams.cambiarTipoAccesoMostrarModal}
            size="lg"
            toggle={() => {
                var state = this._cloneStateForSetState();

                state.searchParams.cambiarTipoAccesoMostrarModal = !state.searchParams.cambiarTipoAccesoMostrarModal;
                this.setState(state);
            }}
        >
            {
                <ModalHeader close={<button className="btn-close" onClick={() => {
                    var state = this._cloneStateForSetState();

                    state.searchParams.cambiarTipoAccesoMostrarModal = false;
                    this.setState(state);
                }} />}>
                    {I18n.Strings.contenidos.cambiarTipoAcceso}
                </ModalHeader>
            }
            <ModalBody>
                <Row>

                    <Col md={6}>

                        <TodosTipoAccesoDropDown
                            showLabel={true}
                            emptyText={I18n.Strings.colegiocredenciales.tipoLicencia + "..."}
                            label={I18n.Strings.colegiocredenciales.tipoLicencia}
                            required={true}
                            id={nameof(this.state.searchParams.cambiarTipoAccesoTipoAccesoPorDefecto)}
                            value={this.state.searchParams.cambiarTipoAccesoTipoAccesoPorDefecto}
                            onChanged={(value: TipoAcceso) => {
                                var newState = this._cloneStateForSetState();
                                newState.searchParams.cambiarTipoAccesoTipoAccesoPorDefecto = value;
                                this.setState(newState);
                            }}

                        />
                        {this._errorMessageCambiarTipoAcceso(nameof(this.state.searchParams.cambiarTipoAccesoTipoAccesoPorDefecto))}
                    </Col>

                    <Col md={6}>
                        <AccedePlataformaDropDown
                            label={I18n.Strings.nivelesContenidos.accedePlataforma}
                            showLabel={true}
                            id={"cursos" + nameof(this.state.searchParams.cambiarTipoAccesoAccedePlataforma)}
                            value={this.state.searchParams.cambiarTipoAccesoAccedePlataforma}
                            className={"form-control"}
                            required={true}
                            onChanged={(value: AccedePlataforma) => {
                                var newState = this._cloneStateForSetState();
                                newState.searchParams.cambiarTipoAccesoAccedePlataforma = value;
                                this.setState(newState);
                            }}
                        />
                        {this._errorMessageCambiarTipoAcceso(nameof(this.state.searchParams.cambiarTipoAccesoAccedePlataforma))}
                    </Col>

                    <Col md={3}>
                        <YesNoDropDown
                            label={I18n.Strings.nivelesContenidos.mostrarAccesoDirecto}
                            showLabel={true}
                            id={"cursos" + nameof(this.state.searchParams.cambiarTipoAccesoMostrarAccesoDirecto)}
                            value={this.state.searchParams.cambiarTipoAccesoMostrarAccesoDirecto == null ? null : this.state.searchParams.cambiarTipoAccesoMostrarAccesoDirecto ? YesNo.Yes.toString() : YesNo.No.toString()}
                            className={"form-control"}
                            required={true}
                            onChanged={(value: YesNo) => {
                                var newState = this._cloneStateForSetState();
                                newState.searchParams.cambiarTipoAccesoMostrarAccesoDirecto = value == YesNo.Yes.toString() ? true : value == YesNo.No.toString() ? false : null;
                                this.setState(newState);
                            }}
                        />
                    </Col>

                    <FormGroup>
                        <Label for={nameof(this.state.searchParams.cambiarTipoAccesoMensajeAcceso)}>
                            {I18n.Strings.colegiocredenciales.mensajeDeshabilitarAcceso}
                        </Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.searchParams.cambiarTipoAccesoMensajeAcceso = evt.target.value;
                                this.setState(state);
                            }}
                            type="textarea"
                            required={this.state.searchParams.cambiarTipoAccesoAccedePlataforma == AccedePlataforma.NoAccedeMuestraMensaje}
                            disabled={this.state.searchParams.cambiarTipoAccesoAccedePlataforma != AccedePlataforma.NoAccedeMuestraMensaje}
                            maxLength={500}
                            name={nameof(this.state.searchParams.cambiarTipoAccesoMensajeAcceso)}
                            id={nameof(this.state.searchParams.cambiarTipoAccesoMensajeAcceso)}
                            value={this.state.searchParams.cambiarTipoAccesoMensajeAcceso || ""}
                        />
                        {this._errorMessageCambiarTipoAcceso(nameof(this.state.searchParams.cambiarTipoAccesoMensajeAcceso))}
                    </FormGroup>

                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" size="lg"

                    onClick={() => {

                        let validation = new ModelValidation();
                        if (((this.state.searchParams.cambiarTipoAccesoTipoAccesoPorDefecto as string) || "").length == 0) {
                            validation.addError(nameof(this.state.searchParams.cambiarTipoAccesoTipoAccesoPorDefecto),
                                I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegiocredenciales.tipoLicencia));
                        }

                        if (((this.state.searchParams.cambiarTipoAccesoAccedePlataforma as string) || "").length == 0) {
                            validation.addError(nameof(this.state.searchParams.cambiarTipoAccesoAccedePlataforma),
                                I18n.Strings.validationErrors.valueRequired(I18n.Strings.nivelesContenidos.accedePlataforma));
                        }

                        if (this.state.searchParams.cambiarTipoAccesoAccedePlataforma == AccedePlataforma.NoAccedeMuestraMensaje) {

                            if ((this.state.searchParams.cambiarTipoAccesoMensajeAcceso || "").length == 0) {
                                validation.addError(nameof(this.state.searchParams.cambiarTipoAccesoMensajeAcceso),
                                    I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegiocredenciales.mensajeDeshabilitarAcceso));
                            }
                        }

                        if (!validation.isOk) {
                            var model = this._cloneStateForSetState();
                            model.searchParams.cambiarTipoAccesoValidationResult = validation;
                            this.setState(model);
                            return;
                        }

                        this.setState({ showLoadingIcon: true }, () => {

                            var modelo = {
                                contenidoIds: this.getIdContenidosChecked().map(p => p.id),
                                accedePlataforma: this.state.searchParams.cambiarTipoAccesoAccedePlataforma,
                                mensajeAcceso: this.state.searchParams.cambiarTipoAccesoMensajeAcceso,
                                mostrarAccesoDirecto: this.state.searchParams.cambiarTipoAccesoMostrarAccesoDirecto,
                                tipoAccesoPorDefecto: this.state.searchParams.cambiarTipoAccesoTipoAccesoPorDefecto
                            };

                            var service = new ContenidosColegioService();
                            var prom = service.cambiarAcceso(modelo);

                            prom.then(() => {
                                var newState = this._cloneStateForSetState();
                                newState.showLoadingIcon = true;
                                newState.searchParams.cambiarTipoAccesoMostrarModal = false;
                                this.setState(newState, () => this._performSearch());
                            })
                                .catch((err) => this.setState({ showLoadingIcon: false }, () => AlertsService.showError(err)));

                        });

                    }}>

                    {I18n.Strings.ok}
                </Button>
                <Button color="secondary" size="lg" onClick={() => {
                    var state = this._cloneStateForSetState();

                    state.searchParams.cambiarTipoAccesoMostrarModal = false;
                    this.setState(state);
                }}>
                    {I18n.Strings.cancel}
                </Button>
            </ModalFooter>
        </Modal>
    }

    protected _errorMessageCambiarTipoAcceso(fieldName: string): JSX.Element {
        var error = this.state.searchParams.cambiarTipoAccesoValidationResult.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }

    protected _renderSearchForm(): React.ReactNode {
        return <React.Fragment>
            {this._renderModalCambiarTipoAcceso()}
            

            <Col xs="12" md="6" lg="6">
                <FormGroup>
                    <Label className="visually-hidden" for={"usuarios" + nameof(this.state.searchParams.nombre)}>
                        {I18n.Strings.licenciasContenido.contenidoBuscador}
                    </Label>
                    <Input
                        placeholder={I18n.Strings.licenciasContenido.contenidoBuscador + "..."}
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.nombre = evt.target.value;
                            this.setState(state);
                        }}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.nombre)}
                        id={"usuarios" + nameof(this.state.searchParams.nombre)}
                        value={this.state.searchParams.nombre || ""}
                    />

                </FormGroup>
            </Col>


            <Col xs="12" md="4" lg="3">

                <EditorialDropDown
                    emptyText={I18n.Strings.licenciasContenido.editorial + "..."}
                    label={I18n.Strings.licenciasContenido.editorial}
                    id={nameof(this.state.searchParams.editorial)}
                    required={false}
                    value={this.state.searchParams.editorial}
                    onChanged={(value: string) => {
                        var newState = this._cloneStateForSetState();
                        newState.searchParams.editorial = value == '' ? null : value as Editorial;
                        this.setState(newState, () => this._performSearch());
                    }}
                />

            </Col>
            {/*
            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label className="visually-hidden" for={"usuarios" + nameof(this.state.searchParams.isbn)}>
                        {I18n.Strings.licenciasContenido.isbn}
                    </Label>
                    <Input
                        placeholder={I18n.Strings.licenciasContenido.isbn + "..."}
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.isbn = evt.target.value;
                            this.setState(state);
                        }}
                        type="text"
                        required={true}
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.isbn)}
                        id={"usuarios" + nameof(this.state.searchParams.isbn)}
                        value={this.state.searchParams.isbn || ""}
                    />

                </FormGroup>
            </Col>
            */}
            <Col xs="12" md="4" lg="3">

                <CursoEscolarDropDown
                    label={null}
                    emptyText={I18n.Strings.licenciasContenido.cursoEscolar}
                    id={nameof(this.state.searchParams.cursoEscolarId)}

                    selectedItemValue={this.state.searchParams.cursoEscolarId}
                    selectedItemText={this.state.searchParams.cursoEscolar}

                    onChanged={(id, nombre) => {

                        var state = this._cloneStateForSetState();
                        state.searchParams.cursoEscolarId = id;
                        state.searchParams.cursoEscolar = nombre;
                        state.searchParams.nivelEducativo = null;
                        state.searchParams.nivelEducativoId = null;
                        this.setState(state, () => this._performSearch());
                    }}
                />

            </Col>

            <Col xs="12" md="4" lg="3">
                <FormGroup>
                    <Label className="visually-hidden" for={nameof(this.state.searchParams.nivelEducativoId)}>
                        {I18n.Strings.matriculas.nombreClase}
                    </Label>

                    <NivelEducativoDropDown
                        emptyText={I18n.Strings.contenidos.nivelesEducativos + "..."}
                        id={nameof(this.state.searchParams.nivelEducativoId)}

                        cursoEscolarId={this.state.searchParams.cursoEscolarId}

                        value={this.state.searchParams.nivelEducativoId}
                        text={this.state.searchParams.nivelEducativo}
                        seleccionClases={false}

                        onChange={(id, nombre) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.nivelEducativoId = id;
                            state.searchParams.nivelEducativo = nombre;
                            this.setState(state, () => this._performSearch());
                        }}
                    />

                </FormGroup>
            </Col>
        </React.Fragment>;
    }

    protected _toolbarRightContents(): JSX.Element[] {

        var contents = super._toolbarRightContents();

       

        var contenidosChecked = this.getIdContenidosChecked();

        if (contenidosChecked != null && contenidosChecked.length > 0) {
            contents.push(<Button
                id="cambiar"
                key="cambiar"
                color="primary"
                className="btn-rounded text-end"
                onClick={() => {
                    var state = this._cloneStateForSetState();

                    var primerSeleccionado = this.getIdContenidosChecked()[0];
                    if (primerSeleccionado && (primerSeleccionado.tipoAccesoPorDefecto || []).length != 0) {
                        state.searchParams.cambiarTipoAccesoTipoAccesoPorDefecto = primerSeleccionado.tipoAccesoPorDefecto[0]
                    }
                    else {
                        state.searchParams.cambiarTipoAccesoTipoAccesoPorDefecto = null;
                    }


                    if (primerSeleccionado && (primerSeleccionado.accedePlataforma || []).length != 0) {
                        state.searchParams.cambiarTipoAccesoAccedePlataforma = primerSeleccionado.accedePlataforma[0]
                    }
                    else {
                        state.searchParams.cambiarTipoAccesoAccedePlataforma = null;
                    }

                    if (primerSeleccionado && (primerSeleccionado.mostrarAccesoDirecto || []).length != 0) {
                        state.searchParams.cambiarTipoAccesoMostrarAccesoDirecto = primerSeleccionado.mostrarAccesoDirecto[0]
                    }
                    else {
                        state.searchParams.cambiarTipoAccesoMostrarAccesoDirecto = null;
                    }

                    state.searchParams.cambiarTipoAccesoMostrarModal = true;


                    state.searchParams.cambiarTipoAccesoValidationResult = new ModelValidation();
                    //state.searchParams.claseNuevaId = null;

                    this.setState(state);
                }}>
                <CheckIcon cssClass="me-2" />
                {I18n.Strings.contenidos.cambiarTipoAccesoNum(contenidosChecked.length)}
            </Button>);
        }


        return contents;
    }

    protected _getApiService(): IServiceForIndex<ContenidosDigitalesColegioSearch, ContenidosDigitalesColegioListItemPaginatedList> {
        return new ContenidosColegioService();
    }

    protected _getTableColumns(): TableColumn<ContenidosDigitalesColegioListItem>[] {
        return [

            {
                fieldName: nameof(this.state.searchResults.items[0].isbn),
                title: I18n.Strings.contenidos.isbn,
                renderField: (item) => item.isbn,

            },

            {
                fieldName: nameof(this.state.searchResults.items[0].nombre),
                title: I18n.Strings.contenidos.nombre,
                renderField: (item) => item.nombre
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].editorial),
                title: I18n.Strings.contenidos.editorial,
                renderField: (item) => {
                    return I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                }
            },

            {
                fieldName: nameof(this.state.searchResults.items[0].cursoEscolar),
                title: I18n.Strings.contenidos.cursoEscolar,
                renderField: (item) => item.cursoEscolar
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].nivelesEducativos),
                title: I18n.Strings.contenidos.nivelesEducativos,
                renderField: (item) => item.nivelesEducativos
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].accedePlataformaStr),
                title: I18n.Strings.nivelesContenidos.accedePlataforma,
                renderField: (item) => {
                    return item.accedePlataforma.map((val) => I18n.Strings.getEnumText(`AccedePlataforma.${val}`)).join(", ");
                }
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].mostrarAccesoDirectoStr),
                title: I18n.Strings.nivelesContenidos.mostrarAccesoDirecto,
                renderField: (item) => {
                    if (item.mostrarAccesoDirecto.length == 1) {
                        return I18n.Strings.formatBool(item.mostrarAccesoDirecto[0]);
                    }
                    else {
                        return "ERROR";
                    }
                }
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].tipoAccesoPorDefectoStr),
                title: I18n.Strings.nivelesContenidos.tipoAccesoPorDefecto,
                renderField: (item) => {
                    return item.tipoAccesoPorDefecto.map((val) => I18n.Strings.getEnumText(`TipoAcceso.${val}`)).join(", ");
                }
            }
        ];
    }

    protected hideGridButtonsOverride() {
        return false;
    }

    protected editButtonOverride(item: ContenidosDigitalesColegioListItem) {
        return <a target="_blank" href={this._getEditionItemUrl(item)}>
            <ViewIcon></ViewIcon>
        </a>
    }
    protected deleteButtonOverride(item) {
        return null;
    }

    protected _getPageTitle(): string {
        return I18n.Strings.contenidos.contenidosColegioTitle;
    }
    protected _getPageDescription(): string {
        return "Listado de contenidos digitales del colegio";
    }

    protected _getEditionItemUrl(item: ContenidosDigitalesColegioListItem): string {
        return RouteHelper.editContenidosColegio(item.id);
    }

    protected _getNewItemUrl(): string {
        return RouteHelper.createNivelesContenido();
    }

    protected _enableExcelExport(): boolean {
        return true;
    }

    protected _calculateCellClass(item: ContenidosDigitalesColegioListItem) {
        return (item.editorial || Editorial.None) == Editorial.None ? "text-danger" : "";
    }
}
