import * as React from "react";

import nameof from "ts-nameof.macro";

import { Link, withRouter } from "react-router-dom";
import { Editorial, ColegioCredencialesEditorialesEditModel, ModelValidationResult, TipoAcceso } from "../models/dataModels";
import { GenericEditState } from "../utils/base/GenericEditState";
import { EditPageBase } from "../utils/base/EditPageBase";
import { IServiceForEdit } from "../utils/base/BaseServices";
import { ColegioCredencialesEditorialesService } from "./ColegioCredencialesEditorialesService";
import { RouteHelper } from "../IntranetRouter";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { I18n } from "../utils/I18n";
import { ModelValidation } from "../utils/base/ModelValidation";
import { EditorialDropDown } from "../helpers/EnumDropDown";
import { UserPasswordIdCentro } from "./UserPasswordIdCentro";
import { TipoAccesoDropDown } from "../licenciasColegio/TipoAccesoDropDown";
import { stat } from "node:fs/promises";
import { Lti11ConfigCentro } from "./Lti11ConfigCentro";
import { Lti13ConfigCentro } from "./Lti13ConfigCentro";

export class EditComp extends EditPageBase<ColegioCredencialesEditorialesEditModel> {

    protected _setPageTitleForNew(): string {
        return "Nuevo"
    }

    protected _setPageTitleForExisting(editModel: ColegioCredencialesEditorialesEditModel): string {
        return `Editar ${editModel.nombre}`;
    }

    protected _createApiService(): IServiceForEdit<ColegioCredencialesEditorialesEditModel> {
        return new ColegioCredencialesEditorialesService();
    }

    protected _generateForm(): JSX.Element {
        return <React.Fragment>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <Label for={"credenciales" + nameof(this.state.editModel.nombre)}>{I18n.Strings.cursoEscolar.nombre}</Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.nombre = evt.target.value;
                                this.setState(state);
                            }}
                            type="text"
                            required={true}
                            maxLength={100}
                            className={"form-control " + this._errorClass(nameof(this.state.editModel.nombre))}
                            name={nameof(this.state.editModel.nombre)}
                            id={"credenciales" + nameof(this.state.editModel.nombre)}
                            value={this.state.editModel.nombre || ""}
                        />
                        {this._errorMessage(nameof(this.state.editModel.nombre))}
                    </FormGroup>
                </Col>
                <Col md="4">
                    <EditorialDropDown
                        emptyText={I18n.Strings.colegiocredenciales.editorial + "..."}
                        label={I18n.Strings.colegiocredenciales.editorial}
                        showLabel={true}
                        id={nameof(this.state.editModel.editorial)}
                        value={this.state.editModel.editorial}
                        required={true}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.editorial))}
                        onChanged={(value: string) => {
                            var newState = this._cloneStateForSetState();
                            if (value != '') {
                                newState.editModel.editorial = value as Editorial;
                            }
                            else {
                                newState.editModel.editorial = null;
                            }

                            this.setState(newState);
                        }}
                    />
                </Col>
                <Col md={4}>
                    <Label for={"credenciales" + nameof(this.state.editModel.tipoLicencia)}>
                        {I18n.Strings.colegiocredenciales.tipoLicencia}
                    </Label>
                    <TipoAccesoDropDown
                        emptyText={I18n.Strings.colegiocredenciales.tipoLicencia + "..."}
                        editorial={this.state.editModel.editorial}
                        //key={`key_tipoacceso_${this.state.editModel.editorial}`  }
                        id={nameof(this.state.editModel.tipoLicencia)}
                        value={this.state.editModel.tipoLicencia}
                        onChange={(value: string) => {
                            var newState = this._cloneStateForSetState();
                            if (value != '') {
                                newState.editModel.tipoLicencia = value as TipoAcceso;
                            }
                            else {
                                newState.editModel.tipoLicencia = null;
                            }
                            this.setState(newState);
                        }}

                    />
                    {this._errorMessage(nameof(this.state.editModel.tipoLicencia))}

                </Col>
            </Row>
            <Row>
                <Col md="12" className="mt-4 mb-3">
                    <h4>Acceso a los contenidos</h4>
                </Col>
            </Row>
            <Row>
                {this.state.editModel.tipoLicencia == TipoAcceso.Lti11 &&
                    <Lti11ConfigCentro
                        required={true}
                        formValidation={this.state.validationResult}
                        value={this.state.editModel.datos}
                        onChange={(value) => {
                            var state = this._cloneStateForSetState();
                            state.editModel.datos = value;
                            this.setState(state);
                        }}
                    />
                }
                {this.state.editModel.tipoLicencia == TipoAcceso.Lti13 &&
                    <Lti13ConfigCentro
                        required={true}
                        formValidation={this.state.validationResult}
                        value={this.state.editModel.datos}
                        onChange={(value) => {
                            var state = this._cloneStateForSetState();
                            state.editModel.datos = value;
                            this.setState(state);
                        }}
                    />
                }
                {this.state.editModel.tipoLicencia != TipoAcceso.Lti11 && this.state.editModel.tipoLicencia != TipoAcceso.Lti13 &&
                    <>

                        {this.state.editModel.editorial != Editorial.Burlington &&
                            <UserPasswordIdCentro
                                required={true}
                                formValidation={this.state.validationResult}
                                value={this.state.editModel.datos}
                                onChange={(value, user, pwd) => {
                                    var state = this._cloneStateForSetState();
                                    state.editModel.datos = value;
                                    this.setState(state);
                                }}
                            />
                        }
                        {this.state.editModel.editorial == Editorial.Burlington &&
                            <p>Burlington: credenciales son propias del hub, para utilizarlo simplemente debe guardar este registro</p>
                        }
                    </>}
            </Row>

            <Row>
                <Col md="12" className="mt-4 mb-3">
                    <h4>Mantenimiento</h4>
                </Col>
            </Row>
            <Row>
                <Col md="12" lg="4">
                    <Input
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.editModel.deshabilitarAcceso = evt.target.checked;
                            if (!state.editModel.deshabilitarAcceso) {
                                state.editModel.mensajeDeshabilitarAcceso = "";
                            }
                            this.setState(state);
                        }}
                        type="checkbox"
                        style={{ padding: 0, marginRight: 10 }}
                        name={nameof(this.state.editModel.deshabilitarAcceso)}
                        id={nameof(this.state.editModel.deshabilitarAcceso)}
                        checked={this.state.editModel.deshabilitarAcceso || false}
                    />
                    <Label for={nameof(this.state.editModel.deshabilitarAcceso)}>{I18n.Strings.colegiocredenciales.deshabilitarAcceso}</Label>
                </Col>

                <Col md="12" lg="8">
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.mensajeDeshabilitarAcceso)}>
                            {I18n.Strings.colegiocredenciales.mensajeDeshabilitarAcceso}
                        </Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.mensajeDeshabilitarAcceso = evt.target.value;
                                this.setState(state);
                            }}
                            type="textarea"
                            required={this.state.editModel.deshabilitarAcceso}
                            disabled={!this.state.editModel.deshabilitarAcceso}
                            maxLength={500}
                            className={"form-control " + this._errorClass(nameof(this.state.editModel.mensajeDeshabilitarAcceso))}
                            name={nameof(this.state.editModel.mensajeDeshabilitarAcceso)}
                            id={nameof(this.state.editModel.mensajeDeshabilitarAcceso)}
                            value={this.state.editModel.mensajeDeshabilitarAcceso || ""}
                        />
                        {this._errorMessage(nameof(this.state.editModel.mensajeDeshabilitarAcceso))}
                    </FormGroup>
                </Col>

            </Row>
        </React.Fragment>;
    }

    protected _getEditUrl(id: number): string {
        return RouteHelper.editCredenciales(id);
    }

    protected _getListUrl(): string {
        return RouteHelper.listCredencialesColegio();
    }

    protected _createStateForNew(): Promise<GenericEditState<ColegioCredencialesEditorialesEditModel>> {

        var model = new GenericEditState<ColegioCredencialesEditorialesEditModel>();
        model.editModel = {
            id: 0,
            nombre: "",
            editorial: null,
            datos: ""
        } as ColegioCredencialesEditorialesEditModel;

        return Promise.resolve(model);
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        if ((this.state.editModel.nombre || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.nombre), I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegiocredenciales.nombre));
        }

        if (this.state.editModel.editorial == null) {
            validation.addError(nameof(this.state.editModel.editorial), I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegiocredenciales.editorial));
        }

        if (this.state.editModel.deshabilitarAcceso) {
            if ((this.state.editModel.mensajeDeshabilitarAcceso || "").trim().length == 0) {
                validation.addError(nameof(this.state.editModel.mensajeDeshabilitarAcceso), I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegiocredenciales.mensajeDeshabilitarAcceso));
            }
        }

        return validation;
    }

}

export const Edit = withRouter(EditComp)