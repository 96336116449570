import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { I18n } from "../utils/I18n";
import { DeleteIcon } from "../utils/Icons";


export class YesNoCancelModalProps {
    YesText: string | null;
    onYes: () => void;

    NoText: string | null;
    onNo: () => void;
    onCancel: () => void;
    confirmTitle?: string;
    confirmBody?: string;
}


export class YesNoCancelModal extends React.Component<YesNoCancelModalProps, any> {



    public render(): JSX.Element {

        var closeButton = <button className="btn-close" onClick={() => this.props.onCancel()}>&nbsp;</button>;

        return <Modal isOpen={true} className="wide-dialog">

            <ModalHeader close={closeButton}>
                {this.props.confirmTitle}
            </ModalHeader>

            <ModalBody>
                <p>{this.props.confirmBody}</p>
            </ModalBody>

            <ModalFooter>
                <Button color="primary"
                    onClick={() => this.props.onYes()}>
                    {this.props.YesText || "SI"}
                </Button>

                <Button color="secondary"
                    onClick={() => this.props.onNo()}
                >
                    {this.props.NoText || "NO"}
                </Button>

                <Button color="secondary"
                    onClick={() => this.props.onCancel()}
                >
                    {I18n.Strings.cancel}
                </Button>

            </ModalFooter>
        </Modal>;
    }
}
