import { stat } from "fs/promises";
import * as React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, DropdownItem, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import nameof from "ts-nameof.macro";
import { AccesosContenido } from "../accesos/AccesosContenido";
import { ColegioUsuariosService } from "../colegioUsuarios/ColegioUsuariosService";
import { ContenidosService } from "../contenidos/maestros/ContenidosService";
import { ContenidosPicker as ContenidosDigitalesPicker, ContenidosDigitalesColegioSearchEx } from "../contenidos/colegio/Picker";

import { ContenidosPicker as ContenidosPapelPicker, ContenidosPapelColegioSearchEx } from "../contenidos/colegioPapel/Picker";

import { CustomDatePicker } from "../helpers/CustomDatePicker";
import { DropDownButton } from "../helpers/DropDownButton";
import { RouteHelper } from "../IntranetRouter";
import { ImpersonationService } from "../login/Impersonate";
import { MatriculasUsuarioDropDown } from "../matriculas/MatriculasUsuarioDropDown";
import { MatriculasPicker } from "../matriculas/Picker";
import { LicenciaContenidosEditModel, OrigenLicencia, MatriculaListItem, TipoAcceso, TipoContenido, TipoGrafico, TipoUsuario, Editorial, TipoUsuarioContenido, YesNo, EstadoLicencia, AccedePlataforma } from "../models/dataModels";
import { AlertsService } from "../utils/AlertsService";
import { IServiceForEdit } from "../utils/base/BaseServices";
import { LinkItem, NavigationHelper } from "../utils/base/Breadcrumb";
import { EditPageBase } from "../utils/base/EditPageBase";
import { GenericEditState } from "../utils/base/GenericEditState";
import { ModelValidation } from "../utils/base/ModelValidation";
import { ClientContext } from "../utils/ClientContext";
import { I18n } from "../utils/I18n";
import { DownloadIcon, UserIcon, ViewIcon } from "../utils/Icons";
import { CredencialesEdit } from "./CredencialesEdit";
import { LicenciaContenidosEditModelEx, LicenciasColegioService } from "./LicenciasColegioService";
import { TipoAccesoDropDown } from "./TipoAccesoDropDown";
import { logAndExtractInfoFromException } from "../utils/ValuesFormatter";
import { AccedePlataformaDropDown, YesNoDropDown } from "../helpers/EnumDropDown";



export enum LicenciaContenidosEditTabs {
    Datos = "datos",
    EstadisticaContenidos = "estadisticas",
    Burlington = "burlington"
}



/*
class UserPasswordObj {
    User: string;
    Password: string;
}
*/

export class EditComp extends EditPageBase<LicenciaContenidosEditModelEx> {

    protected _setPageTitleForNew(): string {
        return "Nueva asignación de licencia"
    }

    protected _setPageTitleForExisting(editModel: LicenciaContenidosEditModelEx): string {
        return `Editar asignación de licencia`;
    }

    protected _createApiService(): IServiceForEdit<LicenciaContenidosEditModelEx> {
        return new LicenciasColegioService() as any as IServiceForEdit<LicenciaContenidosEditModelEx>;
    }

    private _deboMostrarCredenciales(): boolean {

        if (!this.state.editModel.boolContenidoDigital) {
            return false;
        }

        var mostrarCredenciales = this.state.editModel.tipoContenido == TipoContenido.Virtual
            || this.state.editModel.tipoContenido == TipoContenido.Licencia
            || this.state.editModel.editorial == Editorial.Milton
            || this.state.editModel.editorial == Editorial.Ramatics
            || this.state.editModel.editorial == Editorial.Planeta;

        if (mostrarCredenciales &&
            (this.state.editModel.editorial == Editorial.Burlington && this.state.editModel.tipoUsuario == TipoUsuarioContenido.Profesor)) {
            return false;
        }

        return mostrarCredenciales;
    }


    private _paintMainForm(): JSX.Element {

        var puedeEditarNumPedido = (this.state.editModel.id == 0 || this.state.editModel.origen != OrigenLicencia.Tienda)
            || ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal;


        return <React.Fragment>

            <Row>
                {this.state.editModel.id > 0 && <React.Fragment>
                    <Col md={3}>
                        <FormGroup>
                            <Label for={"licencias" + nameof(this.state.editModel.fechaUltimoAcceso)}>{I18n.Strings.colegioUsuarios.fechaUltimoAcceso}</Label>
                            <Input
                                type="text"
                                disabled={true}
                                maxLength={100}
                                className={"form-control"}
                                name={nameof(this.state.editModel.fechaUltimoAcceso)}
                                id={"licencias" + nameof(this.state.editModel.fechaUltimoAcceso)}
                                value={I18n.Strings.formatLastAccessDate(this.state.editModel.fechaUltimoAcceso)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for={"licencias" + nameof(this.state.editModel.created)}>
                                {I18n.Strings.colegioUsuarios.fechaCreacion}
                            </Label>
                            <Input
                                type="text"
                                disabled={true}
                                maxLength={100}
                                className={"form-control"}
                                name={nameof(this.state.editModel.created)}
                                id={"licencias" + nameof(this.state.editModel.created)}
                                value={I18n.Strings.formatDate(this.state.editModel.created) + ' - ' + I18n.Strings.getEnumText("Origen." + this.state.editModel.origen)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for={"licencias" + nameof(this.state.editModel.modified)}>
                                {I18n.Strings.colegioUsuarios.fechaModificacion}
                            </Label>
                            <Input
                                type="text"
                                disabled={true}
                                maxLength={100}
                                className={"form-control"}
                                name={nameof(this.state.editModel.modified)}
                                id={"licencias" + nameof(this.state.editModel.modified)}
                                value={I18n.Strings.formatDate(this.state.editModel.modified) || ""}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for={"licencias" + nameof(this.state.editModel.origen)}>
                                {I18n.Strings.licenciasContenido.origen}
                            </Label>
                            <Input
                                type="text"
                                disabled={true}
                                maxLength={100}
                                className={"form-control"}
                                name={nameof(this.state.editModel.origen)}
                                id={"licencias" + nameof(this.state.editModel.origen)}
                                value={I18n.Strings.getEnumText(`OrigenLicencia.${this.state.editModel.origen}`)}
                            />
                        </FormGroup>
                    </Col>
                </React.Fragment>}
                <Col md={6}>
                    <FormGroup>
                        <Label for={"licencias" + nameof(this.state.editModel.numPedido)}>{I18n.Strings.licenciasContenido.numPedido}</Label>
                        {this.state.editModel.pedidoJsonUrl != null &&
                            <a
                                target="_blank"
                                href={this.state.editModel.pedidoJsonUrl}>
                                <DownloadIcon />
                            </a>
                        }
                        {this.state.editModel.id != 0 && (this.state.editModel.numPedido || "").length != 0 &&
                            <a title="Ver Pedido" href={RouteHelper.detallePedidoTienda(this.state.editModel.numPedido)} target="_blank">
                                <ViewIcon />
                            </a>
                        }
                        <Input
                            type="text"
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.numPedido = evt.target.value;
                                this.setState(state);
                            }}
                            disabled={!puedeEditarNumPedido}
                            className={"form-control "}
                            name={nameof(this.state.editModel.numPedido)}
                            id={"licencias" + nameof(this.state.editModel.numPedido)}
                            value={this.state.editModel.numPedido || ""}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for={"licencias" + nameof(this.state.editModel.fechaPedido)}>
                            {I18n.Strings.licenciasContenido.fechaPedido}
                        </Label>
                        {!puedeEditarNumPedido && <Input
                            type="text"
                            disabled={true}
                            className={"form-control "}
                            name={nameof(this.state.editModel.fechaPedido)}
                            id={"licencias" + nameof(this.state.editModel.fechaPedido)}
                            value={I18n.Strings.formatDate(this.state.editModel.fechaPedido) || ""}
                        />}
                        {puedeEditarNumPedido && <CustomDatePicker
                            key={nameof(this.state.editModel.fechaPedido)}
                            value={this.state.editModel.fechaPedido}
                            yearsFront={2}
                            yearsBack={22}
                            onChange={(val) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.fechaPedido = val == null ? null : new Date(val);
                                if (state.editModel.fechaPedido != null) {
                                    state.editModel.fechaPedido.setHours(12);
                                }
                                this.setState(state);
                            }}
                        />}
                    </FormGroup>
                </Col>
                <Col md={5}>
                    {this.props.match.params.usuarioId != null && <FormGroup>
                        <Label for={nameof(this.state.editModel.matriculaId)}>
                            {I18n.Strings.licenciasContenido.matricula}

                            {this.state.editModel.matriculaId &&
                                <a href={RouteHelper.editMatricula(this.state.editModel.matriculaId, this.state.editModel.usuarioId)}
                                    className="pointer ps-1" target="_blank" >
                                    <ViewIcon />
                                </a>}
                        </Label>

                        <MatriculasUsuarioDropDown
                            label={null}
                            emptyText={""}
                            disabled={!puedeEditarNumPedido}
                            id={nameof(this.state.editModel.matriculaId)}
                            usuarioId={this.props.match.params.usuarioId}
                            cursoEscolarId={this.state.editModel.cursoEscolarId}
                            selectedItemValue={this.state.editModel.matriculaId}
                            selectedItemText={this.state.editModel.matricula}
                            errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.matriculaId))}
                            onChanged={(matriculaId, matricula, item) => {

                                var state = this._cloneStateForSetState();
                                state.editModel.matriculaId = matriculaId;
                                state.editModel.matricula = matricula;

                                if (item.data != null) {
                                    var dataItem = item.data as MatriculaListItem;
                                    state.editModel.tipoUsuario = dataItem.rol;
                                    state.editModel.nivelEducativoId = dataItem.nivelEducativoId;
                                }
                                else {
                                    state.editModel.nivelEducativoId = null;
                                    state.editModel.tipoUsuario = null;
                                }

                                this.setState(state);
                            }}
                        />
                    </FormGroup>}

                    {this.props.match.params.usuarioId == null &&
                        <MatriculasPicker
                            label={<span>
                                {I18n.Strings.licenciasContenido.matricula}
                                {ClientContext.Current.tipoUsuario == TipoUsuario.AdminColegio || ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal &&
                                    <React.Fragment>
                                        {this.state.editModel.id != 0 && this.state.editModel.matriculaId != 0 &&
                                            <a title="Ver matricula" href={RouteHelper.editMatricula(this.state.editModel.matriculaId, this.state.editModel.usuarioId)} target="_blank">
                                                <ViewIcon />
                                            </a>
                                        }
                                        {this.state.editModel.id != 0 && this.state.editModel.usuarioId != 0 &&
                                            <a title="Ver usuario" href={RouteHelper.editUsuarioColegio(this.state.editModel.usuarioId)} target="_blank">
                                                <UserIcon />
                                            </a>}
                                    </React.Fragment>
                                }
                            </span>}
                            required={true}

                            disabled={!puedeEditarNumPedido}
                            itemId={this.state.editModel.matriculaId}
                            itemName={this.state.editModel.matricula}
                            getIdFromItem={(item) => item.id}
                            getNameFromItem={(item) => `${item.usuario} - ${item.clase}- ${item.materia}`}
                            errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.matriculaId))}
                            onSelected={(matriculaId, matricula, item) => {

                                var state = this._cloneStateForSetState();
                                state.editModel.matriculaId = matriculaId;
                                state.editModel.matricula = matricula;
                                state.editModel.usuarioId = item.usuarioId;
                                state.editModel.usuario = item.usuario;
                                state.editModel.nivelEducativoId = item.nivelEducativoId;
                                this.setState(state);
                            }}
                        />
                    }

                </Col>

                <Col md={2} >
                    <Label for={nameof(this.state.editModel.boolContenidoDigital)}>
                        ¿Contenido digital?
                    </Label>
                    <YesNoDropDown
                        emptyText={null}
                        label={null}
                        id={nameof(this.state.editModel.boolContenidoDigital)}
                        value={this.state.editModel.boolContenidoDigital == null ? null : this.state.editModel.boolContenidoDigital ? YesNo.Yes.toString() : YesNo.No.toString()}
                        className={"form-control"}
                        required={true}
                        disabled={!puedeEditarNumPedido}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.boolContenidoDigital))}
                        onChanged={(value: YesNo) => {
                            var newState = this._cloneStateForSetState();

                            newState.editModel.boolContenidoDigital = value == YesNo.Yes.toString() ? true : value == YesNo.No.toString() ? false : null;

                            newState.editModel.contenidoId = null;
                            newState.editModel.contenidoId = null;
                            newState.editModel.productoTiendaId = null;
                            newState.editModel.credenciales = null;

                            this.setState(newState);
                        }}
                    />
                </Col>

                <Col md={5}>
                    {this.state.editModel.boolContenidoDigital &&
                        <ContenidosDigitalesPicker
                            //label={I18n.Strings.licenciasContenido.contenido}
                            size="xl"
                            required={true}
                            label={<span>
                                {I18n.Strings.licenciasContenido.contenido}
                                {this.state.editModel.isbn != null && <> ({this.state.editModel.isbn})</>}
                                {(ClientContext.Current.tipoUsuario == TipoUsuario.AdminColegio || ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal) &&
                                    <React.Fragment>
                                        {this.state.editModel.id != 0 && this.state.editModel.contenidoId != 0 &&
                                            <a title="Ver contenido" href={RouteHelper.editContenidosColegio(this.state.editModel.contenidoId)} target="_blank">
                                                <ViewIcon />
                                            </a>
                                        }
                                    </React.Fragment>
                                }
                            </span>}
                            itemId={this.state.editModel.contenidoId}
                            itemName={this.state.editModel.contenido}
                            getIdFromItem={(item) => item.id}
                            disabled={this.state.editModel.id != 0}
                            mostrarNiveles={true}
                            mostrarSoloColegio={true}
                            key={this.state.editModel.nivelEducativoId || "contPicker"}
                            nivelEducativoId={this.state.editModel.nivelEducativoId}
                            searchParams={{
                                ...{} as ContenidosDigitalesColegioSearchEx,
                                nivelEducativoId: this.state.editModel.nivelEducativoId,
                                claseId: this.state.editModel.claseId,
                            }}
                            getNameFromItem={(item) => {
                                var enumName = I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                                return `${enumName} - ${item.nombre}`;
                            }}
                            cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                            errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.contenidoId))}
                            onSelected={(contenidoId, contenido, item) => {

                                var state = this._cloneStateForSetState();
                                state.editModel.contenidoId = contenidoId;
                                state.editModel.contenido = contenido;
                                state.editModel.editorial = item == null ? null : item.editorial;
                                state.editModel.tipoContenido = item.tipoContenido;
                                state.editModel.tipoAcceso = item.tipoAccesoPorDefecto.length == 1 ? item.tipoAccesoPorDefecto[0] : null;
                                this.setState(state);
                            }}
                        />
                    }

                    {!this.state.editModel.boolContenidoDigital &&
                        <ContenidosPapelPicker
                            //label={I18n.Strings.licenciasContenido.contenido}
                            size="xl"
                            required={true}
                            label={"Contenido papel"}
                            itemId={this.state.editModel.productoTiendaId}
                            itemName={this.state.editModel.contenido}
                            getIdFromItem={(item) => item.id}
                            disabled={this.state.editModel.id != 0}
                            mostrarNiveles={true}
                            mostrarSoloColegio={true}
                            key={this.state.editModel.nivelEducativoId || "contPicker"}
                            nivelEducativoId={this.state.editModel.nivelEducativoId}
                            searchParams={{
                                ...{} as ContenidosPapelColegioSearchEx,
                                nivelEducativoId: this.state.editModel.nivelEducativoId,
                                claseId: this.state.editModel.claseId,
                            }}
                            getNameFromItem={(item) => {
                                var enumName = I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                                return `${enumName} - ${item.nombre}`;
                            }}
                            cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                            errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.productoTiendaId))}
                            onSelected={(productoTiendaId, productoTienda, item) => {

                                var state = this._cloneStateForSetState();
                                state.editModel.productoTiendaId = productoTiendaId;
                                state.editModel.contenido = productoTienda;
                                state.editModel.editorial = item == null ? null : item.editorial;
                                state.editModel.tipoContenido = null;
                                state.editModel.tipoAcceso = null;
                                this.setState(state);
                            }}
                        />
                    }
                </Col>

                {this._deboMostrarCredenciales() && <>
                    <Col md={4}>
                        <FormGroup>
                            <Label for={"licencias" + nameof(this.state.editModel.tipoAcceso)}>{I18n.Strings.colegiocredenciales.tipoLicencia}</Label>
                            <TipoAccesoDropDown
                                key={"licencias" + (this.state.editModel.editorial || "")}
                                id={nameof(this.state.editModel.tipoAcceso)}
                                editorial={this.state.editModel.editorial}
                                className={"form-control " + this._errorClass(nameof(this.state.editModel.tipoAcceso))}
                                onChange={(tipoLicencia: string) => {
                                    var newState = this._cloneStateForSetState();
                                    if (tipoLicencia != '' && tipoLicencia != null) {
                                        newState.editModel.tipoAcceso = tipoLicencia as TipoAcceso;
                                    }
                                    else {
                                        newState.editModel.tipoAcceso = null;
                                    }
                                    newState.editModel.credenciales = "";
                                    this.setState(newState);
                                }}
                                value={this.state.editModel.tipoAcceso}
                            />
                            {this._errorMessage(nameof(this.state.editModel.tipoAcceso))}
                        </FormGroup>

                        <FormGroup>
                            <Label for={"licencias" + nameof(this.state.editModel.tipoUsuario)}>
                                {I18n.Strings.matriculas.rol}
                            </Label>
                            <Input
                                type="text"
                                disabled={true}
                                className={"form-control"}
                                name={nameof(this.state.editModel.tipoUsuario)}
                                id={"licencias" + nameof(this.state.editModel.tipoUsuario)}
                                value={I18n.Strings.getEnumText(`TipoUsuarioContenido.${this.state.editModel.tipoUsuario || ""}`)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <CredencialesEdit
                                key={"credenciales" + + (this.state.editModel.editorial || "") + + (this.state.editModel.tipoAcceso || "")}
                                onChange={(value) => {
                                    var state = this._cloneStateForSetState();
                                    state.editModel.credenciales = value;
                                    this.setState(state);
                                }}
                                name={nameof(this.state.editModel.credenciales)}
                                value={this.state.editModel.credenciales || ""}

                                formValidation={this.state.validationResult}
                                required={true}
                                tipoAcceso={this.state.editModel.tipoAcceso}
                                editorial={this.state.editModel.editorial}
                            />
                        </FormGroup>
                    </Col>
                </>}

                <Col md={4}>

                    <FormGroup>
                        <Label for={"licencias" + nameof(this.state.editModel.editorial)}>{I18n.Strings.licenciasContenido.editorial}</Label>
                        <Input
                            type="text"
                            disabled={true}
                            className={"form-control"}
                            name={nameof(this.state.editModel.editorial)}
                            id={"licencias" + nameof(this.state.editModel.editorial)}
                            value={this.state.editModel.editorial || ""}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>


                </Col>
            </Row>
            <hr />
            <Row>

                <Col md={3}>
                    <FormGroup>
                        <Label for={"licencias" + nameof(this.state.editModel.fechaAsignacionCredenciales)}>{I18n.Strings.licenciasContenido.fechaAsignacionCredenciales}</Label>
                        <Input
                            type="text"
                            disabled={true}
                            maxLength={100}
                            className={"form-control"}
                            name={nameof(this.state.editModel.fechaAsignacionCredenciales)}
                            id={"licencias" + nameof(this.state.editModel.fechaAsignacionCredenciales)}
                            value={I18n.Strings.formatLastAccessDate(this.state.editModel.fechaAsignacionCredenciales)}
                        />
                    </FormGroup>
                </Col>

                <Col md={3}>
                    <FormGroup>
                        <Label for={"licencias" + nameof(this.state.editModel.fechaAsignacionCredenciales)}>{I18n.Strings.licenciasContenido.asignacionCredencialesNotificada}</Label>
                        <Input
                            type="text"
                            disabled={true}
                            maxLength={100}
                            className={"form-control"}
                            name={nameof(this.state.editModel.asignacionCredencialesNotificada)}
                            id={"licencias" + nameof(this.state.editModel.asignacionCredencialesNotificada)}
                            value={I18n.Strings.formatLastAccessDate(this.state.editModel.asignacionCredencialesNotificada)}
                        />
                    </FormGroup>
                </Col>

                <Col md={3}>
                    <FormGroup>
                        <Label for={"licencias" + nameof(this.state.editModel.estado)}>{I18n.Strings.licenciasContenido.estado}</Label>
                        <Input
                            type="text"
                            disabled={true}
                            maxLength={100}
                            className={"form-control"}
                            name={nameof(this.state.editModel.estado)}
                            id={"licencias" + nameof(this.state.editModel.estado)}
                            value={I18n.Strings.getEnumText(`EstadoLicencia.${this.state.editModel.estado}`)}
                        />
                    </FormGroup>
                </Col>

            </Row>

            <hr />
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <Label for={"licencias" + nameof(this.state.editModel.asignacionLicenciaManual)}>
                            {I18n.Strings.licenciasContenido.asignacionLicenciaManual}
                        </Label>
                        <Input
                            onChange={(evt) => {
                                var newState = this._cloneStateForSetState();
                                newState.editModel.asignacionLicenciaManual = evt.target.checked;
                                newState.editModel.accesoPlataformaManual = null;
                                newState.editModel.mensajeAccesoManual = null;
                                this.setState(newState);
                            }}
                            type="checkbox"
                            style={{ padding: 0, marginLeft: 10 }}
                            id={"licencias" + nameof(this.state.editModel.asignacionLicenciaManual)}
                            checked={this.state.editModel.asignacionLicenciaManual}
                        />
                    </FormGroup>
                </Col>
                <Col md={8}>
                    <p>{I18n.Strings.licenciasContenido.asignacionLicenciaManualExplicacion}</p>


                    <FormGroup>

                        <AccedePlataformaDropDown
                            label={I18n.Strings.licenciasContenido.accesoPlataformaManual}
                            showLabel={true}
                            disabled={!this.state.editModel.asignacionLicenciaManual}
                            id={"licencias" + nameof(this.state.editModel.accedePlataforma)}
                            value={this.state.editModel.accesoPlataformaManual}
                            className={"form-control"}
                            required={true}
                            onChanged={(value: AccedePlataforma) => {
                                var newState = this._cloneStateForSetState();
                                newState.editModel.accesoPlataformaManual = value;
                                this.setState(newState);
                            }}
                        />
                    </FormGroup>


                    <FormGroup>
                        <Label for={"licencias" + nameof(this.state.editModel.mensajeAccesoManual)}>
                            {I18n.Strings.licenciasContenido.mensajeAccesoManual}
                        </Label>
                        <Input
                            type="textarea"
                            disabled={!this.state.editModel.asignacionLicenciaManual}
                            maxLength={100}
                            className={"form-control"}
                            name={nameof(this.state.editModel.mensajeAccesoManual)}
                            id={"licencias" + nameof(this.state.editModel.mensajeAccesoManual)}
                            value={this.state.editModel.mensajeAccesoManual || ""}
                            onChange={(evt) => {
                                var newState = this._cloneStateForSetState();
                                newState.editModel.mensajeAccesoManual = evt.target.value;
                                this.setState(newState);
                                
                            } }
                        />
                    </FormGroup>
                </Col>
            </Row>
        </React.Fragment>;
    }

    protected _generateForm(): JSX.Element {
        if ((this.state.editModel.id || 0) == 0) {
            return this._paintMainForm();
        }

        return <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        active={(this.state.activeTab || LicenciaContenidosEditTabs.Datos) == LicenciaContenidosEditTabs.Datos}
                        onClick={() => this.props.history.push(RouteHelper.editLicenciasColegio(this.state.editModel.id, this.props.match.params.usuarioId, this.props.match.params.contenidoId, LicenciaContenidosEditTabs.Datos))}
                    >
                        {I18n.Strings.licenciasContenido.tabDatos}
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        active={this.state.activeTab == LicenciaContenidosEditTabs.EstadisticaContenidos}
                        onClick={() => this.props.history.push(RouteHelper.editLicenciasColegio(this.state.editModel.id, this.props.match.params.usuarioId, this.props.match.params.contenidoId, LicenciaContenidosEditTabs.EstadisticaContenidos))}
                    >
                        {I18n.Strings.licenciasContenido.tabEstadisticas}
                    </NavLink>
                </NavItem>

                {this.state.editModel.id != 0 && this.state.editModel.editorial == Editorial.Burlington &&
                    <NavItem>
                        <NavLink
                            active={this.state.activeTab == LicenciaContenidosEditTabs.Burlington}
                            onClick={() => this.props.history.push(RouteHelper.editLicenciasColegio(this.state.editModel.id, this.props.match.params.usuarioId, this.props.match.params.contenidoId, LicenciaContenidosEditTabs.Burlington))}
                        >
                            {I18n.Strings.licenciasContenido.tabBurlington}
                        </NavLink>
                    </NavItem>
                }
            </Nav>

            <TabContent activeTab={this.state.activeTab || LicenciaContenidosEditTabs.Datos}>
                <TabPane tabId={LicenciaContenidosEditTabs.Datos}>
                    {this._paintMainForm()}
                </TabPane>

                <TabPane tabId={LicenciaContenidosEditTabs.EstadisticaContenidos}>
                    {this.state.activeTab == LicenciaContenidosEditTabs.EstadisticaContenidos &&
                        <React.Fragment>
                            <h2></h2>
                            <Row>
                                <Col md={12}>
                                    <AccesosContenido tipo={TipoGrafico.Licencia} usuarioId={this.state.editModel.usuarioId} licenciaContenidoId={this.state.editModel.id} />
                                </Col>
                            </Row>
                        </React.Fragment>
                    }
                </TabPane>

                {this.state.editModel.id != 0 && this.state.editModel.editorial == Editorial.Burlington &&
                    <TabPane tabId={LicenciaContenidosEditTabs.Burlington}>
                        {this._paintTabBurlington()}
                    </TabPane>
                }

            </TabContent>
        </React.Fragment>;

    }

    protected _getEditUrl(id: number): string {
        return RouteHelper.editLicenciasColegio(id, this.props.match.params.usuarioId, this.props.match.params.contenidoId, LicenciaContenidosEditTabs.Datos);
    }

    protected _getListUrl(): string {
        return RouteHelper.listLicenciasColegio(this.props.match.params.usuarioId, this.props.match.params.contenidoId);
    }

    private _paintTabBurlington(): JSX.Element {
        return <React.Fragment>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.configuradoProveedor)}>{I18n.Strings.licenciasContenido.configuradoProveedor}</Label>
                        <Input
                            type="text"
                            disabled={true}
                            maxLength={100}
                            className={"form-control"}
                            name={nameof(this.state.editModel.configuradoProveedor)}
                            value={I18n.Strings.formatDateTime(this.state.editModel.configuradoProveedor)}
                        />
                    </FormGroup>
                </Col>

                <Col md={4}>
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.estado)}>
                            {I18n.Strings.licenciasContenido.estado}
                        </Label>
                        <Input
                            type="text"
                            disabled={true}
                            maxLength={100}
                            className={"form-control"}
                            name={nameof(this.state.editModel.estado)}
                            value={I18n.Strings.getEnumText(`EstadoLicencia.${this.state.editModel.estado}`)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="table-responsive">
                        <table className="table table-sm table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Accion</th>
                                    <th>Response</th>
                                    <th>Request</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.editModel.logsBurlington.map((item, index) => {
                                    return <tr>
                                        <td>{I18n.Strings.formatDateTime(item.date)}</td>
                                        <td>{I18n.Strings.getEnumText(`TipoAccionBurlington.${item.tipoAccionBurlington}`)}</td>
                                        <td><div dangerouslySetInnerHTML={{ __html: item.respuesta.replaceAll(",", ",<br/>") }}></div> </td>
                                        <td><div dangerouslySetInnerHTML={{ __html: item.peticion.replaceAll(",", ",<br/>") }}></div></td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </React.Fragment>;
    }

    protected _createStateForNew(): Promise<GenericEditState<LicenciaContenidosEditModelEx>> {

        var model = new GenericEditState<LicenciaContenidosEditModelEx>();
        model.editModel = {
            id: 0,
            origen: OrigenLicencia.Administrador,
            cursoEscolarId: ClientContext.Current.cursoEscolarActivoId

        } as LicenciaContenidosEditModelEx;


        if (this.props.match.params.usuarioId != null) {
            let colegioUsuariosSrv = new ColegioUsuariosService();
            let promData = colegioUsuariosSrv.getBasicById(this.props.match.params.usuarioId);

            let modelWithDataProm = promData.then(data => {
                model.editModel.usuario = data.nombre;
                model.editModel.usuarioId = data.id;
                return model;
            });

            return modelWithDataProm;
        }
        if (this.props.match.params.contenidoId != null) {
            let contenidosSrv = new ContenidosService();
            let promData = contenidosSrv.getBasicById(this.props.match.params.contenidoId);

            let modelWithDataProm = promData.then(data => {
                model.editModel.contenido = data.nombre;
                model.editModel.contenidoId = data.id;
                return model;
            });

            return modelWithDataProm;
        }
        else {

            return Promise.resolve(model);
        }

    }


    protected _validateModelLocal(): ModelValidation {


        // TODO: MBERNAL esto está duplicado en el editor de crendenciales, quizas debamos meter un ref+metodo publico y hacer alli la validación
        var credencialesConUsuarioYPassword = (this.state.editModel.editorial == Editorial.Santillana && this.state.editModel.tipoAcceso == TipoAcceso.Custom)
            || (this.state.editModel.editorial == Editorial.Milton)
            || (this.state.editModel.editorial == Editorial.Planeta);

        let validation = new ModelValidation();


        if (this.state.editModel.boolContenidoDigital == null) {
            validation.addError(nameof(this.state.editModel.boolContenidoDigital), I18n.Strings.validationErrors.valueRequired(""));
        }

        if (this.state.editModel.boolContenidoDigital && this.state.editModel.contenidoId == null) {
            validation.addError(nameof(this.state.editModel.contenidoId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.licenciasContenido.contenido));
        }

        if (!this.state.editModel.boolContenidoDigital && this.state.editModel.productoTiendaId == null) {
            validation.addError(nameof(this.state.editModel.productoTiendaId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.licenciasContenido.contenido));
        }

        if (this.state.editModel.matriculaId == null) {
            validation.addError(nameof(this.state.editModel.matriculaId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.licenciasContenido.matricula));
        }

        if (!this._deboMostrarCredenciales()) {
            this.state.editModel.credenciales = null;
        }
        else {
            /*
            // TODO: MBERNAL esto está duplicado en el editor de crendenciales, quizas debamos meter un ref+metodo publico y hacer alli la validación
            if (credencialesConUsuarioYPassword) {

                var userPassword = JSON.parse(this.state.editModel.credenciales) as UserPasswordObj;

                if (userPassword != null && ((userPassword.User || "").trim().length == 0 || (userPassword.Password || "").trim().length == 0)) {
                    validation.addError(nameof(this.state.editModel.credenciales), I18n.Strings.validationErrors.valueRequired(I18n.Strings.licenciasContenido.credenciales));
                }
            }
            else {
                
                if ((this.state.editModel.credenciales || "").trim().length == 0) {
                    validation.addError(nameof(this.state.editModel.credenciales), I18n.Strings.validationErrors.valueRequired(I18n.Strings.licenciasContenido.credenciales));
                }
                
            }
            */
        }

        if (this.state.editModel.boolContenidoDigital) {
            if (this.state.editModel.tipoAcceso == null || this.state.editModel.tipoAcceso == TipoAcceso.SinConfigurar) {
                validation.addError(nameof(this.state.editModel.tipoAcceso), I18n.Strings.validationErrors.valueRequired(I18n.Strings.licenciasContenido.tipoAcceso));
            }
        }

        return validation;
    }

    protected _extraButtons(): JSX.Element {

        if (this.state.editModel.id == 0 || this.state.editModel.usuarioId == 0 ||
            (ClientContext.Current.tipoUsuario != TipoUsuario.AdminGlobal && ClientContext.Current.tipoUsuario != TipoUsuario.AdminColegio)) {
            return <></>;
        }

        var botones = [];
        botones.push(<Button
            onClick={() => {
                var impService = new ImpersonationService();
                impService.impersonate(this.state.editModel.usuarioId);
            }}>
            {I18n.Strings.emularUsuario.emularUsuario}
        </Button>);

        if (this.state.editModel.id != 0) {
            botones.push(<DropdownItem divider={true} />);
            botones.push(<Button
                onClick={() => {
                    if (window.confirm(I18n.Strings.licenciasContenido.borrarCredencialesWarning)) {
                        this.setState({ showLoading: true }, () => {

                            var licenciaService = new LicenciasColegioService();
                            var prom = licenciaService.borrarCredenciales(this.state.editModel.id);

                            prom
                                .then((res) => {
                                    var newState = this._cloneStateForSetState();
                                    newState.editModel.credenciales = "";
                                    newState.showLoading = false;
                                    this.setState(newState);
                                })
                                .catch(err => {
                                    this.setState({ showLoading: false }, () => AlertsService.showError(err));
                                });

                        });
                    }
                }
                }>
                {I18n.Strings.licenciasContenido.borrarCredenciales}
            </Button >);
        }



        if (this.state.editModel.id != 0 && this.state.editModel.editorial == Editorial.Burlington) {
            if (ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal) {

                botones.push(<DropdownItem key={botones.length} divider={true} />);
                botones.push(<Button
                    key={botones.length}
                    onClick={() => {

                        if (this.state.editModel.estado == EstadoLicencia.BurlingtonDeletedUser) {
                            if (!window.confirm("El usuario fue desactivado, para re-activarlo hay que notificar a Soporte Burlington")) {
                                return;
                            }
                        }

                        this.setState({ showLoading: true }, () => {

                            var licenciasService = new LicenciasColegioService();
                            var prom = licenciasService.syncBurlington(this.state.editModel.id);
                            prom.then(resp => {
                                this.setState({ showLoading: false },
                                    () => {
                                        AlertsService.showAlertMessage(`<b>Request<b>:${resp.request}<br/><br/><b>Response</b>${resp.response}`);
                                    });

                            })
                                .catch(err => {
                                    this.setState({ showLoading: false },
                                        () => AlertsService.showError(err));
                                });
                        });
                    }}
                >
                    Sincronizar con Burlington
                </Button>);



                botones.push(<Button
                    key={botones.length}
                    onClick={() => {
                        var email = window.prompt("Email del usuario a borrar en burlington?");
                        if ((email || "").length != 0) {
                            var licenciasService = new LicenciasColegioService();
                            var prom = licenciasService.deleteBurlington(email);
                            prom.then(resp => {
                                AlertsService.showAlertMessage(`<b>Request<b>:${resp.request}<br/><br/><b>Response</b>${resp.response}`);
                            })
                                .catch(err => AlertsService.showError(err));

                        }
                    }}
                >
                    Borrar usuario en Burlington
                </Button>);


                botones.push(<DropdownItem key={botones.length} divider={true} />);
                botones.push(<Button
                    key={botones.length}
                    onClick={() => {
                        var continuar = window.confirm("Va a sincronizar todos los alumnos del colegio, ¿está seguro?");
                        if (continuar) {
                            var licenciasService = new LicenciasColegioService();
                            var prom = licenciasService.syncColegioBurlington();
                            prom.then(resp => {
                                AlertsService.showAlertMessage(`<b>Request<b>:<span>${resp.request}</span><br/><br/><b>Response</b>${resp.response}`);
                            })
                                .catch(err => AlertsService.showError(err));

                        }
                    }}
                >
                    Sincronizar todo los alumnos en burlington
                </Button>);
            }
        }

        return <DropDownButton botones={botones} />
    }

    protected updateBreadCrumb(model: LicenciaContenidosEditModelEx, isInitialMount?: boolean) {

        if (!isInitialMount) return;

        var items = [] as LinkItem[];
        if (this.props.match.params.usuarioId) {
            items.push({
                text: I18n.Strings.colegioUsuarios.title,
                link: RouteHelper.listUsuariosColegio()
            });

            items.push({
                text: model.usuario,
                link: RouteHelper.editUsuarioColegio(this.props.match.params.usuarioId)
            });
        }
        else if (this.props.match.params.contenidoId) {

            items.push({
                text: I18n.Strings.contenidos.contenidosColegioTitle,
                link: RouteHelper.listContenidosColegio()
            });

            items.push({
                text: model.contenido,
                link: RouteHelper.editContenidosColegio(this.props.match.params.contenidoId)
            });
        }

        items.push({
            text: I18n.Strings.licenciasContenido.entityPluralName,
            link: RouteHelper.listLicenciasColegio(this.props.match.params.usuarioId, this.props.match.params.contenidoId)
        });

        if (model.id == 0) {
            items.push({
                text: I18n.Strings.addNew,
                link: ""
            });
        }
        else {
            items.push({
                text: `${model.id}`,
                link: RouteHelper.editLicenciasColegio(model.id,
                    this.props.match.params.usuarioId,
                    this.props.match.params.contenidoId,
                    LicenciaContenidosEditTabs.Datos)
            });
        }

        switch (this.state.activeTab) {
            case LicenciaContenidosEditTabs.Datos: {
                items.push({
                    text: I18n.Strings.licenciasContenido.tabDatos,
                    link: RouteHelper.editLicenciasColegio(this.state.editModel.id,
                        this.props.match.params.usuarioId,
                        this.props.match.params.contenidoId,
                        LicenciaContenidosEditTabs.Datos)
                });
                break;
            }
            case LicenciaContenidosEditTabs.EstadisticaContenidos: {
                items.push({
                    text: I18n.Strings.colegioUsuarios.tabEstadisticasContenidos,
                    link: RouteHelper.editLicenciasColegio(this.state.editModel.id,
                        this.props.match.params.usuarioId,
                        this.props.match.params.contenidoId,
                        LicenciaContenidosEditTabs.EstadisticaContenidos)
                });
                break;
            }
        }

        NavigationHelper.setBreadcrumbItems(items);
    }

}

export const Edit = withRouter(EditComp)