import * as React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import nameof from "ts-nameof.macro";
import { DocumentUpload } from "../../helpers/DocumentUpload";
import { EditorialDropDown, TipoUsuarioContenidoDropDown } from "../../helpers/EnumDropDown";
import { RouteHelper } from "../../IntranetRouter";
import { ContenidosEditModel, Editorial, TipoUsuarioContenido } from "../../models/dataModels";
import { AlertsService } from "../../utils/AlertsService";
import { IServiceForEdit } from "../../utils/base/BaseServices";
import { EditPageBase } from "../../utils/base/EditPageBase";
import { GenericEditState } from "../../utils/base/GenericEditState";
import { ModelValidation } from "../../utils/base/ModelValidation";
import { I18n } from "../../utils/I18n";
import { isEqual as _isEqual } from "lodash-es";
import { ContenidosService } from "./ContenidosService";
import { Location as HistoryLocation } from "history";
import { NavigationHelper } from "../../utils/base/Breadcrumb";
import { AliasEditorialDown } from "../../aliasEditoriales/AliasEditorialesDropDown";


export enum ContenidoEditTabs {
    Datos = "datos",
    Estructura = "estructura",
}

export class EditComp extends EditPageBase<ContenidosEditModel> {

    protected _setPageTitleForNew(): string {
        return "Nuevo"
    }

    protected _setPageTitleForExisting(editModel: ContenidosEditModel): string {
        return `Editar ${editModel.nombre}`;
    }

    protected _createApiService(): IServiceForEdit<ContenidosEditModel> {
        return new ContenidosService() as any as IServiceForEdit<ContenidosEditModel>;
    }

    protected _skipExitPrompt(location: HistoryLocation): boolean {

        if (location.pathname == RouteHelper.editContenidos(this.state.editModel.id, ContenidoEditTabs.Datos) ||
            location.pathname == RouteHelper.editContenidos(this.state.editModel.id, ContenidoEditTabs.Estructura)) {
            return true;
        }
        return super._skipExitPrompt(location);
    }

    protected _generateForm(): JSX.Element {

        if (this.state.editModel.id === 0) {
            return this._paintMainForm();
        }

        return <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        active={(this.state.activeTab || ContenidoEditTabs.Datos) == ContenidoEditTabs.Datos}
                        onClick={() => this.props.history.push(RouteHelper.editContenidos(this.state.editModel.id, ContenidoEditTabs.Datos))}
                    >
                        {I18n.Strings.contenidos.tabDatos}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={this.state.activeTab == ContenidoEditTabs.Estructura}
                        onClick={() => this.props.history.push(RouteHelper.editContenidos(this.state.editModel.id, ContenidoEditTabs.Estructura))}
                    >
                        {I18n.Strings.contenidos.tabEstructura}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab || ContenidoEditTabs.Datos}>
                <TabPane tabId={ContenidoEditTabs.Datos}>
                    {this._paintMainForm()}
                </TabPane>
                <TabPane tabId={ContenidoEditTabs.Estructura}>
                    {this._paintEstructura()}
                </TabPane>
            </TabContent>

        </React.Fragment>;
    }

    private _paintEstructura(): JSX.Element {

        if ((this.state.editModel.estructura || []).length == 0) {
            return <React.Fragment>
                <p>{I18n.Strings.contenidos.noHayEstructura}</p>
                <Button onClick={() => {
                    this.setState({ showLoading: true },
                        () => {
                            var contService = new ContenidosService();
                            contService.syncEstructura(this.state.editModel.id)
                                .then((data) => {
                                    window.location.reload();
                                })
                                .catch(err => this.setState({ showLoading: false }, () => AlertsService.showError(err)));
                        }
                    );
                }}>
                    {I18n.Strings.contenidos.sincronizarEstructura}
                </Button>
            </React.Fragment>;
        }

        return <div>
            <ul>
                {this.state.editModel.estructura.map((libro, idxLibro) => {
                    return <li key={idxLibro}>
                        <b>{libro.titulo}</b>
                        <ul>
                            {libro.unidades.sort((a, b) => a.orden > b.orden ? 1 : -1).map((unidad, idxUnidad) => {
                                return <li key={idxUnidad}>
                                    <b>{unidad.nombre}</b>
                                    <ul>
                                        {(unidad.actividades || []).sort((a, b) => a.orden > b.orden ? 1 : -1).map((actividad, idxActividad) => {
                                            return <li key={idxActividad}>
                                                {actividad.nombre}
                                            </li>;
                                        })}
                                    </ul>
                                </li>
                            })}
                        </ul>
                    </li>
                })}
            </ul>
        </div>;
    }

    private _paintMainForm(): JSX.Element {
        return <React.Fragment>
            <Row>
                <Col md={9}>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for={"contenidos" + nameof(this.state.editModel.nombre)}>{I18n.Strings.contenidos.nombre}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.nombre = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={true}
                                    maxLength={100}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.nombre))}
                                    name={nameof(this.state.editModel.nombre)}
                                    disabled={this.state.editModel.id != 0}
                                    id={"contenidos" + nameof(this.state.editModel.nombre)}
                                    value={this.state.editModel.nombre || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.nombre))}
                            </FormGroup>
                        </Col>

                        <Col md={6}>
                            <FormGroup>
                                <Label for={"contenidos" + nameof(this.state.editModel.isbn)}>{I18n.Strings.contenidos.isbn}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.isbn = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={true}
                                    maxLength={100}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.isbn))}
                                    name={nameof(this.state.editModel.isbn)}
                                    disabled={this.state.editModel.id != 0}
                                    id={"contenidos" + nameof(this.state.editModel.isbn)}
                                    value={this.state.editModel.isbn || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.isbn))}
                            </FormGroup>
                        </Col>

                        <Col md={12}>
                            <FormGroup>
                                <Label for={"contenidos" + nameof(this.state.editModel.descripcion)}>{I18n.Strings.contenidos.descripcion}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.descripcion = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="textarea"
                                    required={true}
                                    maxLength={500}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.descripcion))}
                                    name={nameof(this.state.editModel.descripcion)}
                                    disabled={this.state.editModel.id != 0}
                                    id={"contenidos" + nameof(this.state.editModel.descripcion)}
                                    value={this.state.editModel.descripcion || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.descripcion))}
                            </FormGroup>
                        </Col>

                        <Col md={3}>
                            <AliasEditorialDown
                                label={I18n.Strings.contenidos.editorial}
                                id={nameof(this.state.editModel.aliasEditorialId)}
                                selectedItemValue={this.state.editModel.aliasEditorialId}
                                selectedItemText={this.state.editModel.aliasEditorial}
                                errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.aliasEditorialId))}
                                required={true}
                                disabled={this.state.editModel.id != 0}
                                onChanged={(value, text) => {
                                    var newState = this._cloneStateForSetState();
                                    newState.editModel.aliasEditorial = text;
                                    newState.editModel.aliasEditorialId = value;
                                    this.setState(newState);
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <TipoUsuarioContenidoDropDown
                                showLabel={true}
                                label={I18n.Strings.contenidos.tipoUsuario}
                                id={nameof(this.state.editModel.tipoUsuario)}
                                value={this.state.editModel.tipoUsuario}
                                errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.tipoUsuario))}
                                required={true}
                                onChanged={(value: TipoUsuarioContenido) => {
                                    var newState = this._cloneStateForSetState();
                                    newState.editModel.tipoUsuario = value;
                                    this.setState(newState);
                                }}
                            />
                        </Col>

                        <Col md={2}>
                            <FormGroup>
                                <Label for={"sincronizar" + nameof(this.state.editModel.sincronizar)}>{I18n.Strings.etapa.sincronizar}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var newState = this._cloneStateForSetState();
                                        newState.editModel.sincronizar = evt.target.checked;
                                        this.setState(newState);
                                    }}
                                    type="checkbox"
                                    style={{ padding: 0 }}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.sincronizar))}
                                    id={nameof(this.state.editModel.sincronizar)}
                                    checked={this.state.editModel.sincronizar || false}
                                />
                            </FormGroup>
                        </Col>

                        <Col md={4}>
                            <FormGroup>
                                <Label for={"identificadorTienda" + nameof(this.state.editModel.identificadorTienda)}>{I18n.Strings.etapa.identificadorTienda}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var newState = this._cloneStateForSetState();
                                        newState.editModel.identificadorTienda = evt.target.value;
                                        this.setState(newState);
                                    }}
                                    type="text"
                                    required={false}
                                    maxLength={50}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.identificadorTienda))}
                                    id={nameof(this.state.editModel.identificadorTienda)}
                                    value={this.state.editModel.identificadorTienda || ""}
                                />
                            </FormGroup>
                        </Col>


                        <Col md={4}>
                            <FormGroup>
                                <Label for={"identificadorTienda" + nameof(this.state.editModel.identificadorEditorial)}>{I18n.Strings.contenidos.identificadorEditorial}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var newState = this._cloneStateForSetState();
                                        newState.editModel.identificadorEditorial = evt.target.value;
                                        this.setState(newState);
                                    }}
                                    type="text"
                                    required={false}
                                    maxLength={50}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.identificadorEditorial))}
                                    id={nameof(this.state.editModel.identificadorEditorial)}
                                    value={this.state.editModel.identificadorEditorial || ""}
                                />
                            </FormGroup>
                        </Col>



                    </Row>
                </Col>
                <Col md={3}>
                    <div>
                        <div className="mainPhoto">
                            <img
                                className="mt-xl"
                                src={this.state.editModel.caratula == null ? '/img/caratula.png' : this.state.editModel.caratula}
                            />
                            {this.state.editModel.caratula != null && <div className="mainPhotoMenu">

                            </div>
                            }
                        </div>
                        {this.state.editModel.id > 0 && <div>
                            <DocumentUpload
                                manualUpload={false}
                                style={{ fontSize: 14, overflow: "ellipsis" }}
                                title={I18n.Strings.contenidos.subirFoto}
                                zoneStyle={{ margin: 0, padding: 10 }}
                                uploadUrl={`intranet/contenidos/${this.state.editModel.id}/profilePhoto`}
                                multiple={false}
                                onUpload={(data) => {
                                    this._updateStateValue(data.documentUrl, nameof.full(this.state.editModel.caratula, 2));
                                }}
                            />
                        </div>}
                    </div>
                </Col>
            </Row>
        </React.Fragment>;
    }

    protected _editModelIsEqual(model: ContenidosEditModel, oldModel: ContenidosEditModel) {

        var editPart = { ...model };
        editPart.estructura = null;

        var oldEditPart = { ...oldModel };
        oldEditPart.estructura = null;

        return _isEqual(editPart, oldEditPart);
    }

    protected _getEditUrl(id: number): string {
        return RouteHelper.editContenidos(id, ContenidoEditTabs.Datos);
    }

    protected _getListUrl(): string {
        return RouteHelper.listContenidos();
    }

    protected _createStateForNew(): Promise<GenericEditState<ContenidosEditModel>> {

        var model = new GenericEditState<ContenidosEditModel>();
        model.editModel = {
            id: 0,

        } as ContenidosEditModel;

        return Promise.resolve(model);
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        if ((this.state.editModel.nombre || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.nombre), I18n.Strings.validationErrors.valueRequired(I18n.Strings.contenidos.nombre));
        }

        if ((this.state.editModel.descripcion || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.descripcion), I18n.Strings.validationErrors.valueRequired(I18n.Strings.contenidos.descripcion));
        }

        if ((this.state.editModel.aliasEditorialId || 0) == 0) {
            validation.addError(nameof(this.state.editModel.aliasEditorialId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.contenidos.editorial));
        }

        if ((this.state.editModel.tipoUsuario || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.tipoUsuario), I18n.Strings.validationErrors.valueRequired(I18n.Strings.contenidos.tipoUsuario));
        }

        return validation;
    }

    protected updateBreadCrumb(model: ContenidosEditModel) {
        var items = [{
            text: I18n.Strings.contenidos.contenidosMaestrosTitle,
            link: RouteHelper.listContenidos()
        }];
        if (model.id == 0) {
            items.push({
                text: I18n.Strings.addNew,
                link: ""
            });
        }
        else {
            items.push({
                text: `${model.nombre}`,
                link: RouteHelper.editContenidosColegio(model.id)
            });

            switch (this.state.activeTab) {
                case ContenidoEditTabs.Datos: {
                    items.push({ text: I18n.Strings.contenidos.tabDatos, link: RouteHelper.editContenidos(this.state.editModel.id, ContenidoEditTabs.Datos) });
                    break;
                }
                case ContenidoEditTabs.Estructura: {
                    items.push({ text: I18n.Strings.contenidos.tabEstructura, link: RouteHelper.editContenidos(this.state.editModel.id, ContenidoEditTabs.Estructura) });
                    break;
                }
            }
        }

        NavigationHelper.setBreadcrumbItems(items);
    }
}

export const Edit = withRouter(EditComp)
