import { stat } from "fs";
import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label } from "reactstrap";
import nameof from "ts-nameof.macro";
import { ColegiosDropDown } from "../colegios/ColegiosDropDown";
import { CustomDatePicker } from "../helpers/CustomDatePicker";
import { EstadoPedidoScholarumDropDown } from "../helpers/EnumDropDown";
import { RouteHelper } from "../IntranetRouter";
import { LicenciaContenidosEditTabs } from "../licenciasColegio/Edit";
import { EstadoPedidoScholarum, PedidoColegioListItem, PedidoColegioListItemPaginatedList, PedidosColegioSearch, PedidosTiendaSearch, PedidoTiendaListItem, PedidoTiendaListItemPaginatedList, SortDirection, TipoUsuario } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { ClientContext } from "../utils/ClientContext";
import { I18n } from "../utils/I18n";
import { ExcelIcon } from "../utils/Icons";
import { toCamelCase } from "../utils/ValuesFormatter";
import { PedidosColegioService } from "../pedidosColegio/PedidosColegioService";


interface PedidosColegioSearchEx extends PedidosColegioSearch {
    colegio: string;
}

class IndexComp extends IndexPageBase<PedidosColegioSearchEx, PedidoColegioListItemPaginatedList, PedidoColegioListItem>
{

    protected _getInitialSearchParams(): PedidosColegioSearchEx {


        let fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 6);

        let model = super._getInitialSearchParams();
        //model.fechaCompraFrom =  fromDate;
        model.orderMode = SortDirection.Desc;
        model.colegioId = ClientContext.Current.colegioId;
        model.colegio = ClientContext.Current.nombreColegio;
        model.soloDevoluciones = true;
        return model;
    }

    protected _getApiService(): IServiceForIndex<PedidosColegioSearch, PedidoColegioListItemPaginatedList> {
        return new PedidosColegioService();
    }


    protected _enableExcelExport() {
        return true;
    }


    protected _renderSearchForm(): React.ReactNode {

        if (this.props.isEmbedded) {
            return null;
        }

        return <React.Fragment>

            <Col xs="12" md="6" lg="4">
                <FormGroup>
                    <Label for={nameof(this.state.searchParams.nombre)}>
                        {I18n.Strings.pedidosColegio.nombre}
                    </Label>
                    <Input
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.nombre = evt.target.value;
                            this.setState(state);
                        }}
                        placeholder={I18n.Strings.pedidosColegio.nombre + "..."}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.nombre)}
                        id={"usuarios" + nameof(this.state.searchParams.nombre)}
                        value={this.state.searchParams.nombre || ""}
                    />
                </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label for={nameof(this.state.searchParams.fechaPresupuestoFrom)}>
                        {I18n.Strings.pedidosColegio.fechaDevolucionFrom}
                    </Label>
                    <CustomDatePicker
                        key={nameof(this.state.searchParams.fechaPresupuestoFrom)}
                        value={this.state.searchParams.fechaPresupuestoFrom}
                        yearsFront={2}
                        yearsBack={3}
                        onChange={(val) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.fechaPresupuestoFrom = val;
                            this.setState(state, () => this._performSearch());
                        }}
                    />
                </FormGroup>
            </Col>

            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label for={nameof(this.state.searchParams.fechaPresupuestoTo)}>
                        {I18n.Strings.pedidosColegio.fechaDevolucionTo}
                    </Label>
                    <CustomDatePicker
                        key={nameof(this.state.searchParams.fechaPresupuestoTo)}
                        value={this.state.searchParams.fechaPresupuestoTo}
                        yearsFront={2}
                        yearsBack={3}
                        onChange={(val) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.fechaPresupuestoTo = val;
                            this.setState(state, () => this._performSearch());
                        }}
                    />
                </FormGroup>
            </Col>
            
        </React.Fragment>;
    }



    protected _getTableColumns(): TableColumn<PedidoColegioListItem>[] {

        let columns = [];

        columns.push({
            fieldName: nameof(this.state.searchResults.items[0].nombre),
            title: I18n.Strings.pedidosColegio.nombre,
            renderField: (item: PedidoColegioListItem) => item.nombre
        });

        if (ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal) {
            columns.push({
                fieldName: nameof(this.state.searchResults.items[0].colegio),
                title: toCamelCase(I18n.Strings.colegio.entityName),
                renderField: (item: PedidoColegioListItem) => item.colegio,

            });
        }

        columns.push({
            fieldName: nameof(this.state.searchResults.items[0].fechaPresupuesto),
            title: I18n.Strings.pedidosColegio.fechaDevolucion,
            renderField: (item: PedidoColegioListItem) => I18n.Strings.formatDate(item.fechaPresupuesto)
        });

        columns.push({
            fieldName: nameof(this.state.searchResults.items[0].numItems),
            title: I18n.Strings.pedidosColegio.numUnidadesDevueltas,
            renderField: (item: PedidoColegioListItem) => {
                return I18n.Strings.formatNumber(Math.abs(item.numItems));
            }
        });

        // TODO: Meter el numero total de unidades pedidas

        // TODO: Quitamos los pedidos cancelados

        // TODO: Filtrar las devoluciones: Tipo de Operación - devolución o Nombre PVA - DEV

        return columns;
    }


    protected _getPageTitle(): string {
        return I18n.Strings.pedidosColegio.devolucionTitle;
    }
    protected _getPageDescription(): string {
        return I18n.Strings.pedidosColegio.devolucionDescription;
    }

    protected _getNewItemUrl(): string {
        return null;
    }

    protected _getEditionItemUrl(item: PedidoColegioListItem): string {
        return RouteHelper.detalleDevolucionesColegio(item.id);
    }

}

export const Index = withRouter(IndexComp);