import * as React from "react";

import nameof from "ts-nameof.macro";

import { Link, withRouter } from "react-router-dom";
import { ClaseMateriaContenidosEditModel, GrupoClaseEditModel, ClaseMateriaEditModel, Origen, TipoUsuarioContenido, TipoUsuario, ColegioUsuariosSearch } from "../../models/dataModels";
import { GenericEditState } from "../../utils/base/GenericEditState";
import { EditPageBase } from "../../utils/base/EditPageBase";
import { IServiceForEdit } from "../../utils/base/BaseServices";
import { GrupoClaseService } from "./GrupoClaseService";
import { RouteHelper } from "../../IntranetRouter";
import { Alert, Button, Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { I18n } from "../../utils/I18n";
import { ModelValidation } from "../../utils/base/ModelValidation";
import { CustomDatePicker } from "../../helpers/CustomDatePicker";
import { AddNewIcon, DeleteIcon, EditIcon } from "../../utils/Icons";
import { NivelContenidoDropDown } from "../../nivelesContenidos/NivelContenidoDropDown";
import { NivelProductoTiendaDropDown } from "../../nivelesContenidos/NivelProductoTiendaDropDown";
import { EditorialDropDown, TipoUsuarioContenidoDropDown } from "../../helpers/EnumDropDown";
import { DeleteModal } from "../../utils/DeleteModal";
import { LinkItem, NavigationHelper } from "../../utils/base/Breadcrumb";
import { Index as Alumnos, ColegioUsuariosSearchEx } from "../../colegioUsuarios/Index";
import { Licencias } from "./Licencias";
import { Matriculas } from "./Matriculas";

export enum GrupoClaseEditTabs {
    Datos = "Datos",
    Alumnos = "Alumnos"
}

interface GrupoClaseEditModelEx extends GrupoClaseEditModel {

    //cursoEscolarId?: number;
    indexMateriaBorrar?: number;
    claseMateriaContenidosABorrarIndex?: number;
    claseMateriaProductoTiendaABorrarIndex?: number;
}

export class ClaseEditComp extends EditPageBase<GrupoClaseEditModelEx> {

    protected _setPageTitleForNew(): string {
        return "Nuevo"
    }

    protected _setPageTitleForExisting(editModel: GrupoClaseEditModel): string {
        return `Editar ${editModel.nombre}`;
    }

    protected _createApiService(): IServiceForEdit<GrupoClaseEditModel> {
        return new GrupoClaseService();
    }

    protected updateBreadCrumb(model: GrupoClaseEditModel) {

        var items = [
            {
                text: I18n.Strings.cursoEscolar.title,
                link: RouteHelper.listCursosEscolares()
            },
            {
                text: model.cursoEscolar,
                link: RouteHelper.editCursoEscolar(model.cursoEscolarId)
            },
            {
                text: model.id == 0 ? I18n.Strings.addNew : `${model.nombre}`,
                link: ""
            }
        ] as LinkItem[];

        NavigationHelper.setBreadcrumbItems(items);
    }

    protected _generateForm(): JSX.Element {
        if ((this.state.editModel.id || 0) == 0) {
            return this._paintMainForm();
        }

        return <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        active={(this.state.activeTab || GrupoClaseEditTabs.Datos) == GrupoClaseEditTabs.Datos}
                        onClick={() => this.props.history.push(RouteHelper.editGrupoClase(this.state.editModel.id, this.state.editModel.cursoEscolarId, GrupoClaseEditTabs.Datos))}
                    >
                        {I18n.Strings.colegioUsuarios.tabDatos}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={this.state.activeTab == GrupoClaseEditTabs.Alumnos}
                        onClick={() => this.props.history.push(RouteHelper.editGrupoClase(this.state.editModel.id, this.state.editModel.cursoEscolarId, GrupoClaseEditTabs.Alumnos))}
                    >
                        {I18n.Strings.colegioUsuarios.tabAlumnos}
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab || GrupoClaseEditTabs.Datos}>
                <TabPane tabId={GrupoClaseEditTabs.Datos}>
                    {this._paintMainForm()}
                </TabPane>
                <TabPane tabId={GrupoClaseEditTabs.Alumnos}>
                    <Alumnos isEmbedded={true}
                        searchModel={{
                            ... {} as ColegioUsuariosSearchEx,
                            tipoUsuario: TipoUsuario.Alumno,
                            claseId: this.state.editModel.id
                        }}
                    />
                </TabPane>
            </TabContent>
        </React.Fragment >;
    }

    private _paintMainForm(): JSX.Element {
        return <React.Fragment>
            {this.state.showDeleteConfirmation != null &&
                <DeleteModal
                    onCancelDelete={() => this.setState({ showDeleteConfirmation: null })}
                    deleteConfirmBody={(typeof this.state.showDeleteConfirmation == "boolean") ? null : this.state.showDeleteConfirmation}
                    onConfirm={() => {
                        var state = this._cloneStateForSetState();
                        state.showDeleteConfirmation = null;
                        if (this.state.editModel.indexMateriaBorrar != null) {
                            if (this.state.editModel.claseMateriaContenidosABorrarIndex != null) {
                                state.editModel.materias[this.state.editModel.indexMateriaBorrar].claseMateriaContenidos.splice(this.state.editModel.claseMateriaContenidosABorrarIndex, 1);
                            }
                            else {
                                state.editModel.materias.splice(this.state.editModel.indexMateriaBorrar, 1);
                            }

                            state.editModel.indexMateriaBorrar = null;
                            state.editModel.claseMateriaContenidosABorrarIndex = null;
                        }

                        this.setState(state);
                    }}
                />
            }
            <Row>
                {this.state.editModel.id > 0 && <React.Fragment>
                    <Col md={4}>
                        <FormGroup>
                            <Label for={"usuarios" + nameof(this.state.editModel.nombre)}>{I18n.Strings.grupoClase.nombre}</Label>
                            <Input
                                type="text"
                                onChange={(evt) => {
                                    var state = this._cloneStateForSetState();
                                    state.editModel.nombre = evt.target.value;
                                    this.setState(state);
                                }}
                                disabled={this.state.editModel.nombre.indexOf('General') > 0}
                                maxLength={100}
                                className={"form-control " + this._errorClass(nameof(this.state.editModel.nombre))}
                                name={nameof(this.state.editModel.nombre)}
                                id={"usuarios" + nameof(this.state.editModel.nombre)}
                                value={this.state.editModel.nombre}
                            />
                            {this._errorMessage(nameof(this.state.editModel.nombre))}
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for={"claselabel" + nameof(this.state.editModel.nombre)}>{I18n.Strings.etapa.identificadorTienda}</Label>
                            <Input
                                type="text"
                                onChange={(evt) => {
                                    var state = this._cloneStateForSetState();
                                    state.editModel.identificadorTienda = evt.target.value;
                                    this.setState(state);
                                }}
                                maxLength={100}
                                className={"form-control " + this._errorClass(nameof(this.state.editModel.identificadorTienda))}
                                name={nameof(this.state.editModel.identificadorTienda)}
                                id={"clase" + nameof(this.state.editModel.identificadorTienda)}
                                value={this.state.editModel.identificadorTienda}
                            />
                            {this._errorMessage(nameof(this.state.editModel.identificadorTienda))}
                        </FormGroup>
                    </Col>
                </React.Fragment>}
            </Row>
            {this._renderMaterias()}
        </React.Fragment>;
    }

    private _renderMaterias(): JSX.Element {
        return <React.Fragment>

            {this.state.editModel.materias != null && this.state.editModel.materias.length > 0 &&
                <Row className="mt-4">
                    <Col md={7}>
                        <h3>{I18n.Strings.grupoClase.materias}</h3>
                    </Col>
                    <Col md={1}>

                    </Col>
                </Row>
            }
            {this.state.editModel.materias != null && this.state.editModel.materias.map((claseMateria, indexMateria) => {
                return <div className={"licencias lista_etapas"} key={"materia_" + indexMateria}>
                    <Row>
                        <Col md={7}>
                            <FormGroup>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        var materia = state.editModel.materias[indexMateria];
                                        materia.nombre = evt.target.value;
                                        this.setState(state);
                                    }}
                                    placeholder={I18n.Strings.grupoClase.nombre}
                                    type="text"
                                    required={true}
                                    maxLength={100}
                                    className={"form-control " + this._errorClass("materia" + indexMateria)}
                                    name={"materia" + indexMateria}
                                    id={"materia" + indexMateria}
                                    value={claseMateria.nombre || ""}
                                />
                                {this._errorMessage("materia" + indexMateria)}
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label>
                                    <Input
                                        onChange={(evt) => {
                                            var state = this._cloneStateForSetState();
                                            var materia = state.editModel.materias[indexMateria];
                                            materia.borrado = evt.target.checked;
                                            this.setState(state);
                                        }}
                                        type="checkbox"
                                        title={I18n.Strings.grupoContenido.borrado}
                                        style={{ padding: 0 }}
                                        className={this._errorClass(nameof(claseMateria.borrado))}
                                        name={"borrado" + indexMateria}
                                        id={"borrado" + indexMateria}
                                        checked={claseMateria.borrado || false}
                                    />
                                    <span className="ps-2">
                                        {I18n.Strings.grupoContenido.borrado}
                                    </span>
                                </Label>
                                {this._errorMessage("borrado" + indexMateria)}
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <a href="javaScript:void(0)"
                                    title={I18n.Strings.claseMateria.deleteClaseMateria(claseMateria.nombre)}
                                    className={"btn-niveles btn-borrarnivel"}
                                    style={{ border: "none" }}
                                    onClick={() => {
                                        var state = this._cloneStateForSetState();

                                        state.showDeleteConfirmation = I18n.Strings.grupoClase.deleteGrupoConfirmText(claseMateria.nombre);
                                        state.editModel.indexMateriaBorrar = indexMateria;

                                        this.setState(state);
                                    }}>
                                    <DeleteIcon />
                                </a>

                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <Matriculas
                                claseId={claseMateria.claseId}
                                claseMateriaId={claseMateria.id}
                                numMatriculas={claseMateria.numMatriculas}
                            />
                            
                        </Col>

                    </Row>
                    {claseMateria.claseMateriaContenidos != null && claseMateria.claseMateriaContenidos.length > 0 &&
                        <React.Fragment>
                            <Row>
                                <Col md={1}>
                                </Col>
                                <Col md={6}>
                                    <Label>{I18n.Strings.grupoContenido.contenido}</Label>
                                </Col>
                                <Col md={2}>
                                    <Label>{I18n.Strings.contenidos.tipoUsuario}</Label>
                                </Col>
                            </Row>
                            {claseMateria.claseMateriaContenidos.map((claseMateriaContenido, indexClaseMateriaContenido) => {
                                return <Row key={"nivel_" + indexClaseMateriaContenido}>
                                    <Col md={1}>
                                        
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <NivelContenidoDropDown
                                                claseId={this.state.editModel.id}
                                                cursoEscolarId={this.props.match.params.cursoEscolarId}
                                                selectedItemValue={claseMateriaContenido.nivelContenidoId}
                                                selectedItemText={null}
                                                label={null}
                                                onChanged={(id, name, data) => {
                                                    var state = this._cloneStateForSetState();
                                                    var nivel = state.editModel.materias[indexMateria].claseMateriaContenidos[indexClaseMateriaContenido];
                                                    nivel.nivelContenidoId = id;
                                                    //materia.contenido = name;
                                                    this.setState(state);
                                                }}
                                            />
                                            {this._errorMessage("contenido" + indexMateria + indexClaseMateriaContenido)}
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <TipoUsuarioContenidoDropDown
                                            showLabel={false}
                                            label={I18n.Strings.contenidos.tipoUsuario}
                                            id={"tipo" + indexMateria + indexClaseMateriaContenido}
                                            value={claseMateriaContenido.tipoUsuario}
                                            errorMessage={this.state.validationResult.getError("tipo" + indexMateria + indexClaseMateriaContenido)}
                                            required={true}
                                            onChanged={(value: any) => {
                                                var state = this._cloneStateForSetState();
                                                var nivel = state.editModel.materias[indexMateria].claseMateriaContenidos[indexClaseMateriaContenido];
                                                nivel.tipoUsuario = value != "" ? value as TipoUsuarioContenido : null;
                                                this.setState(state);
                                            }}
                                        />
                                    </Col>
                                    <Col md={1}>
                                        <FormGroup>
                                            <a href="javaScript:void(0)"
                                                title={I18n.Strings.grupoContenido.deleteContenido}
                                                className={"btn-niveles btn-borrarnivel"}
                                                style={{ border: "none" }}
                                                onClick={() => {

                                                    var state = this._cloneStateForSetState();
                                                    state.showDeleteConfirmation = true;
                                                    state.editModel.indexMateriaBorrar = indexMateria;
                                                    state.editModel.claseMateriaContenidosABorrarIndex = indexClaseMateriaContenido;

                                                    this.setState(state);
                                                }}>
                                                <DeleteIcon />
                                            </a>
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Licencias claseId={this.state.editModel.id} claseMateriaId={claseMateriaContenido.claseMateriaId} numLicencias={claseMateriaContenido.numLicencias} />
                                    </Col>
                                </Row>
                            })}

                        </React.Fragment>
                    }
                    {/* Papel*/ }
                    {claseMateria.claseMateriaProductosTienda != null && claseMateria.claseMateriaProductosTienda.length > 0 &&
                        <React.Fragment>
                            <Row>
                                <Col md={1}>
                                </Col>
                                <Col md={8}>
                                    <Label>{I18n.Strings.grupoContenido.contenido}</Label>
                                </Col>
                               
                            </Row>
                            {claseMateria.claseMateriaProductosTienda.map((claseMateriaProductoTienda, indexClaseMateriaProductoTienda) => {
                                return <Row key={"nivel_" + indexClaseMateriaProductoTienda}>
                                    <Col md={1}>

                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <NivelProductoTiendaDropDown
                                                claseId={this.state.editModel.id}
                                                cursoEscolarId={this.props.match.params.cursoEscolarId}
                                                selectedItemValue={claseMateriaProductoTienda.nivelProductoTiendaId}
                                                selectedItemText={null}
                                                label={null}
                                                onChanged={(id, name, data) => {
                                                    var state = this._cloneStateForSetState();
                                                    var nivel = state.editModel.materias[indexMateria].claseMateriaProductosTienda[indexClaseMateriaProductoTienda];
                                                    nivel.nivelProductoTiendaId = id;
                                                    //materia.contenido = name;
                                                    this.setState(state);
                                                }}
                                            />
                                            {this._errorMessage("contenido" + indexMateria + indexClaseMateriaProductoTienda)}
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md={1}>
                                        <FormGroup>
                                            <a href="javaScript:void(0)"
                                                title={I18n.Strings.grupoContenido.deleteContenido}
                                                className={"btn-niveles btn-borrarnivel"}
                                                style={{ border: "none" }}
                                                onClick={() => {

                                                    var state = this._cloneStateForSetState();
                                                    state.showDeleteConfirmation = true;
                                                    state.editModel.indexMateriaBorrar = indexMateria;
                                                    state.editModel.claseMateriaProductoTiendaABorrarIndex = indexClaseMateriaProductoTienda;

                                                    this.setState(state);
                                                }}>
                                                <DeleteIcon />
                                            </a>
                                        </FormGroup>
                                    </Col>
                                    
                                </Row>
                            })}

                        </React.Fragment>
                    }

                    <Row>
                        <Col sm={1}></Col>
                        <Col>
                            <Button className={"btn-niveles btn-sm btn-rounded"}
                                onClick={() => {
                                    var state = this._cloneStateForSetState();
                                    var claseMateria = state.editModel.materias[indexMateria];
                                    claseMateria.claseMateriaContenidos.push({
                                        claseMateriaId: claseMateria.id,
                                        tipoUsuario: null,
                                        nivelContenidoId: null
                                    } as ClaseMateriaContenidosEditModel);
                                    this.setState(state);
                                }}>
                                <AddNewIcon />
                                {I18n.Strings.grupoContenido.addContenido}
                            </Button>
                        </Col>
                    </Row>
                </div>
            })}
            <Row>
                <Col md={12}>
                    <button
                        className="btn btn-secondary btn-rounded btn-sm"
                        onClick={() => {
                            var state = this._cloneStateForSetState();
                            if (state.editModel.materias == null) {
                                state.editModel.materias = [];
                            }
                            state.editModel.materias.push({
                                nombre: '',
                                origen: Origen.Administrador,
                                id: 0,
                                claseMateriaContenidos: [],
                            } as ClaseMateriaEditModel);
                            this.setState(state);
                        }}>
                        <AddNewIcon />
                        {I18n.Strings.grupoContenido.addMateria}
                    </button>
                </Col>
            </Row>
            {this.state.validationResult != null && !this.state.validationResult.isOk && <Row className="mt-5">
                <Col sm={12}>
                    <Alert color="danger">
                        <Row>
                            <Col md={9}>
                                {I18n.Strings.validationErrors.thereAreErrors}
                            </Col>
                        </Row>
                    </ Alert>
                </Col>
            </Row>}
        </React.Fragment>;
    }


    protected _getEditUrl(id: number): string {
        return RouteHelper.editGrupoClase(id, this.props.match.params.cursoEscolarId);
    }

    protected _getListUrl(): string {
        return RouteHelper.editCursoEscolar(this.props.match.params.cursoEscolarId);
    }

    protected _createStateForNew(): Promise<GenericEditState<GrupoClaseEditModel>> {

        var model = new GenericEditState<GrupoClaseEditModel>();
        model.editModel = {
            id: 0
        } as GrupoClaseEditModel;

        return Promise.resolve(model);
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        if ((this.state.editModel.nombre || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.nombre), I18n.Strings.validationErrors.valueRequired(I18n.Strings.grupoClase.nombre));
        }

        if (this.state.editModel.materias != null) {
            this.state.editModel.materias.map((materia, index) => {
                if ((materia.nombre || "").trim().length == 0) {
                    validation.addError("materia" + index, I18n.Strings.validationErrors.valueRequired(I18n.Strings.grupoContenido.nombre));
                }
                else {
                    var countfiltered = this.state.editModel.materias.filter(function (element) {
                        return element.nombre == materia.nombre;
                    }).length;
                    if (countfiltered > 1) {
                        validation.addError("materia" + index, I18n.Strings.validationErrors.valueDuplicated(I18n.Strings.grupoContenido.nombre));
                    }
                }

                if (materia.claseMateriaContenidos != null) {
                    materia.claseMateriaContenidos.map((nivel, index2) => {
                        if ((nivel.nivelContenidoId == null)) {
                            validation.addError("contenido" + index + index2, I18n.Strings.validationErrors.valueRequired(I18n.Strings.grupoContenido.contenido));
                        }
                        if ((nivel.tipoUsuario == null)) {
                            validation.addError("tipo" + index + index2, I18n.Strings.validationErrors.valueRequired(I18n.Strings.contenidos.tipoUsuario));
                        }
                    })
                }
            });
        }

        return validation;
    }

}

export const Edit = withRouter(ClaseEditComp)