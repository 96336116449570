import strict from "node:assert/strict";
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { AccedePlataforma, GetLicenciaAccesoUrlResponse, LicenciaContenidosItem, TipoContenido } from "../models/dataModels";
import { ResultOk } from "../utils/ActionResults";
import { AlertsService } from "../utils/AlertsService";
import { ApiFetcher } from "../utils/ApiFetcher";
import { I18n } from "../utils/I18n";
import { Loading } from "../utils/Loading";
import { logAndExtractInfoFromException } from "../utils/ValuesFormatter";


interface ContenidoProps {
    licencia: LicenciaContenidosItem;
}

interface ContenidoState {
    loading: boolean;
    textToShowInError: string;
    textToShowInMessage: string;
    htmlToInject: string;
}

/***
 * Esta clase se encarga de pintar los enlaces de las licencias en la home.
 * 
 */
export default class LicenciaEnlace extends React.Component<ContenidoProps, ContenidoState> {

    public constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            textToShowInError: null,
            textToShowInMessage: null,
            htmlToInject: null
        };
    }

    public render() {

        var style = {} as React.CSSProperties;
        if (!this.props.licencia.puedeInteractuar) {
            style.backgroundColor = "unset";
        }

        return <div className="ficha-cont" style={style}>
            {this.state.textToShowInError != null &&
                <ResultOk messageToShow={this.state.textToShowInError} onDismiss={() => this.setState({ textToShowInError: null })} />
            }

            {this.state.textToShowInMessage != null &&
                <Modal id="mdlAccesoContenido" isOpen={true} toggle={() => this.setState({ textToShowInMessage: null })}>
                    <ModalHeader
                        close={<button className="btn-close" onClick={() => this.setState({ textToShowInMessage: null })} />}
                    >
                    </ModalHeader>
                    <ModalBody>
                        <div
                            onClick={(evt) => {
                                debugger;
                                var target = (evt.target as HTMLElement);
                                if (target.classList.contains("copy-clip")) {
                                    navigator.clipboard.writeText(target.textContent)
                                        .then(() => {
                                            target.classList.add('flash');
                                            // Eliminar clase despu�s de 2 segundos
                                            setTimeout(() => {
                                                target.classList.remove('flash');
                                            }, 2000);
                                        })
                                        .catch((err) => alert("No se ha podido copiar"));
                                }
                            }}
                            dangerouslySetInnerHTML={{ __html: this.state.textToShowInMessage }}
                        >
                        </div>
                    </ModalBody>
                </Modal>
            }

            {this.state.loading && <Loading />}
            <div className="imagen-cont">

                <a href="#" onClick={(evt) => this.accederPlataformaEditorial(evt, this.props.licencia)} target="_blank">
                    <img src={this.props.licencia.caratula} className="img-fluid" alt={this.props.licencia.nombre} />
                </a>

            </div>
            <div className="tit-cont">
                {this.props.licencia.nombre}
            </div>
            <div className="autor-cont">
                <div className="editorial">
                    {(this.props.licencia.editorialNombre || "").length != 0 && <>{this.props.licencia.editorialNombre}</>}
                    {(this.props.licencia.editorialNombre || "").length == 0 && <>{I18n.Strings.getEnumText(`Editorial.${this.props.licencia.editorial}`)}</>}
                </div>

                {this.props.licencia.clase}
            </div>
            {this.state.htmlToInject != null && <div dangerouslySetInnerHTML={{ __html: this.state.htmlToInject }}></div>}
        </div>;
    }

    private accederPlataformaEditorial(evt: React.MouseEvent<HTMLAnchorElement>, licencia: LicenciaContenidosItem) {
        evt.preventDefault();

        this.setState({ loading: true },
            () => {

                var apiFetcher = new ApiFetcher();
                var prom = apiFetcher.getByUrl<GetLicenciaAccesoUrlResponse>(`intranet/licencias/mias/${licencia.licenciaContenidoId}/acceder`);


                prom.then((result: GetLicenciaAccesoUrlResponse) => {

                    if (result.puedeAcceder) {

                        if ((result.url || "").length > 0) {
                            this.abrirUrl(result);
                        }
                        else if ((result.htmlToInject || "").length > 0) {
                            this.abrirForm(result);
                        }
                        else if ((result.mensajeUsuario || "").length > 0) {
                            this.setState({
                                loading: false,
                                textToShowInMessage: result.mensajeUsuario
                            });
                        }
                        else {
                            alert("No Implementado!");
                        }
                        this.setState({ loading: false });
                    }
                    else {
                        this.setState({
                            loading: false,
                            textToShowInError: result.mensajeUsuario
                        });
                    }
                })
                    .catch((error) => {
                        this.setState({
                            loading: false,
                            textToShowInError: logAndExtractInfoFromException(error)
                        });
                    });
            }
        );
    }

    private abrirUrl(result: GetLicenciaAccesoUrlResponse) {

        var resultWindow = window.open(result.url, "_blank");

        if (!resultWindow) {

            this.setState({ loading: false }, () => {
                AlertsService.showAlertMessage(`<p>Parece, que su dispositivo tiene activado el bloqueador de popups, asi que los contenidos se abrir&aacute;n en esta misma ventana.</p>
                        <p>Para permitir que se abran los contenidos en otra ventana, siga las siguientes instrucciones:</p>
                        <ul>
                            <li>
                                <a target="_blank" href='https://support.google.com/chrome/answer/95472?hl=es&co=GENIE.Platform%3DDesktop#zippy=%2Cpermitir-las-ventanas-emergentes-y-las-redirecciones-de-un-sitio'>
                                Si est&aacute; usando Chrome (Secci&oacute;n "Permitir las ventanas emergentes y las redirecciones de un sitio")
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://support.apple.com/es-es/guide/safari/sfri40696/mac">
                                Si est&aacute; usando Safari
                                </a>
                            </li>
                        </ul>
                        <p>Para acceder al contenido, pulse sobre <b>Aceptar</b></p>
                    `,
                    () => {
                        window.location.href = result.url;
                    });
            });
            return false;
        }
    }

    private abrirForm(result: GetLicenciaAccesoUrlResponse) {
        this.setState({ htmlToInject: result.htmlToInject },
            () => {
                if ((result.scriptToInject || "").length != 0) {
                    eval(result.scriptToInject);
                }
            }
        );
    }
}