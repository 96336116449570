import React from 'react'; // we need this to make JSX compile
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { ModelValidation } from "../utils/base/ModelValidation";

interface UserPasswordIdCentroProps {
    value: string;
    required: boolean;

    formValidation: ModelValidation;
    onChange: (json: string, user: string, pwd: string, idCentro: string) => void;
}

export const UserPasswordIdCentro = function (props: UserPasswordIdCentroProps) {

    class UserPassword {
        User: string;
        Password: string;
        IdCentro: string;
    }

    var valueObj = {
        User: "",
        Password: "",
        IdCentro: ""
    } as UserPassword;

    try {
        valueObj = JSON.parse(props.value) as UserPassword;
    } catch { }

    const composeVal = function () {
        var value = {
            User: null,
            Password: null,
            IdCentro: null
        };

        return JSON.stringify(value);
    }


    const userpass_user = "userPassUser"
    const userpass_pass = "passPassUser"
    const userpass_center = "centerPassUser"

    var errorUsuario = props.formValidation.getError(userpass_user) || "";
    var errorPassword = props.formValidation.getError(userpass_pass) || "";
    var errorIdCentro = props.formValidation.getError(userpass_center) || "";


    return <React.Fragment>
        <Col md="12" lg="4">
            <FormGroup>
                <Label for={userpass_user}>Usuario</Label>
                <Input
                    onChange={(evt) => {
                        valueObj.User = evt.target.value;
                        props.onChange(JSON.stringify(valueObj), valueObj.User, valueObj.Password, valueObj.IdCentro);
                    }}
                    type="text"
                    required={true}
                    maxLength={100}
                    className={"form-control " + (errorUsuario.length == 0 ? "" : "is-invalid")}
                    name={userpass_user}
                    id={userpass_user}
                    value={valueObj.User || ""}
                />

                {(errorUsuario || "").length != 0 && <FormFeedback>
                    {errorUsuario}
                </FormFeedback>}

            </FormGroup>
        </Col>
        <Col md="12" lg="4">
            <FormGroup>
                <Label for={userpass_center}>Id Centro</Label>
                <Input
                    onChange={(evt) => {
                        valueObj.IdCentro = evt.target.value;
                        props.onChange(JSON.stringify(valueObj), valueObj.User, valueObj.Password, valueObj.IdCentro);
                    }}
                    type="text"
                    required={true}
                    maxLength={100}
                    className={"form-control " + (errorIdCentro.length == 0 ? "" : "is-invalid")}
                    name={userpass_center}
                    id={userpass_center}
                    value={valueObj.IdCentro || ""}
                />

                {(errorIdCentro || "").length != 0 && <FormFeedback>
                    {errorIdCentro}
                </FormFeedback>}

            </FormGroup>
        </Col>
        <Col md="12" lg="4">
            <FormGroup>
                <Label for={userpass_pass} >Password</Label>
                <Input
                    onChange={(evt) => {
                        valueObj.Password = evt.target.value;
                        props.onChange(JSON.stringify(valueObj), valueObj.User, valueObj.Password, valueObj.IdCentro);
                    }}
                    type="text"
                    required={true}
                    maxLength={100}
                    className={"form-control " + (errorPassword.length == 0 ? "" : "is-invalid")}
                    name={userpass_pass}
                    id={userpass_pass}
                    value={valueObj.Password || ""}
                />

                {(errorPassword || "").length != 0 && <FormFeedback>
                    {errorPassword}
                </FormFeedback>}

            </FormGroup>
        </Col>

        
    </React.Fragment>;
}
