import nameof from "ts-nameof.macro";
import { ExportFileResult, ModelValidationResult, PedidoColegioListItemPaginatedList, PedidosColegioSearch, PedidoColegioDetailResult } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../utils/base/BaseServices";
import { downloadFileFromBase64Array } from "../utils/ValuesFormatter";


export class PedidosColegioService
    implements IServiceForIndex<PedidosColegioSearch, PedidoColegioListItemPaginatedList>, IServiceForEdit<PedidoColegioDetailResult>
{


    search(searchData: PedidosColegioSearch): Promise<PedidoColegioListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<PedidosColegioSearch, PedidoColegioListItemPaginatedList>("intranet/pedidos-colegio/search", searchData);

        prom.then(res => {
            
            res.items.forEach((val) => {
                val.fechaCompromiso = val.fechaCompromiso != null ? new Date(val.fechaCompromiso) : null;
                val.fechaPresupuesto = val.fechaPresupuesto != null ? new Date(val.fechaPresupuesto) : null;
            });

            return res;
        });

        return prom;
    }

    delete(id: number): Promise<any> {
        throw new Error("Not Implemented");
    }

    getById(id: string | number): Promise<PedidoColegioDetailResult> {
        
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<PedidoColegioDetailResult>(`intranet/pedidos-colegio/${id}`);
        prom = prom.then(model => {

            model.fechaCompromiso = model.fechaCompromiso == null ? null : new Date(model.fechaCompromiso);
            model.fechaPresupuesto = model.fechaPresupuesto == null ? null : new Date(model.fechaPresupuesto);
            return model;
        });
        return prom;
    }

    validate(model: PedidoColegioDetailResult): Promise<ModelValidationResult> {
        throw new Error("Method not implemented.");
    }

    create(model: PedidoColegioDetailResult): Promise<PedidoColegioDetailResult> {
        throw new Error("Method not implemented.");
    }

    update(id: string | number, model: PedidoColegioDetailResult): Promise<PedidoColegioDetailResult> {
        throw new Error("Method not implemented.");
    }

    exportExcel(search: PedidosColegioSearch): Promise<any> {
        var url = "intranet/pedidos-colegio/exportExcel";
        debugger;
        var apiFetcher = new ApiFetcher();
        var promise = apiFetcher.post<PedidosColegioSearch, ExportFileResult>(url, search)
            .then((result) => {
                downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return promise;
    }
}