
import { LicenciaContenidosEditModel, LicenciasContenidosListItemPaginatedList, LicenciasContenidosSearch, ModelValidationResult, GetTiposLicenciaResponse, ExportFileResult, BurlingtonOperationResult } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../utils/base/BaseServices";
import { downloadFileFromBase64Array } from "../utils/ValuesFormatter";

export class LicenciasColegioService
    implements IServiceForIndex<LicenciasContenidosSearch, LicenciasContenidosListItemPaginatedList>, IServiceForEdit<LicenciaContenidosEditModel>
{
    

    search(searchData: LicenciasContenidosSearch): Promise<LicenciasContenidosListItemPaginatedList> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<LicenciasContenidosSearch, LicenciasContenidosListItemPaginatedList>("intranet/colegio/licencias-contenido/search", searchData);
        prom.then(res => {
            res.items.forEach(item => {
                item.fechaPedido = item.fechaPedido == null ? null : new Date(item.fechaPedido);
                item.fechaUltimoAcceso = item.fechaUltimoAcceso == null ? null : new Date(item.fechaUltimoAcceso);
            });
        });
        return prom;
    }

    delete(id: number): Promise<any> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.delete<any>(`intranet/colegio/licencias-contenido/${id}`);
        return prom;
    }


    deleteMultiple(ids: number[]): Promise<any> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.delete<any>(`intranet/colegio/licencias-contenido/deleteMultiple?ids=${ids.join(",")}`);
        return prom;
    }

    borrarCredenciales(id: number): Promise<boolean> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<any, boolean>(`intranet/colegio/licencias-contenido/${id}/limpiarCredenciales`, null);
        return prom;
    }

    getById(id: string | number): Promise<LicenciaContenidosEditModelEx> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<LicenciaContenidosEditModelEx>(`intranet/colegio/licencias-contenido/${id}`)
            .then(item => {
            this.processEditModel(item);
            return item;
        });
        return prom;
    }

    validate(model: LicenciaContenidosEditModel): Promise<ModelValidationResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<LicenciaContenidosEditModel, ModelValidationResult>(`intranet/colegio/licencias-contenido/validate`, model);
        return prom;
    }

    create(model: LicenciaContenidosEditModel): Promise<LicenciaContenidosEditModelEx> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<LicenciaContenidosEditModel, LicenciaContenidosEditModelEx>(`intranet/colegio/licencias-contenido/`, model)
            .then(item => {
                this.processEditModel(item);
                return item;
            });
        return prom;
    }

    update(id: string | number, model: LicenciaContenidosEditModelEx): Promise<LicenciaContenidosEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<LicenciaContenidosEditModel, LicenciaContenidosEditModelEx>(`intranet/colegio/licencias-contenido/${id}`, model)
            .then(item => {
                this.processEditModel(item);
                return item;
            });
        return prom;
    }

    processEditModel(model: LicenciaContenidosEditModelEx) {
        model.fechaPedido = model.fechaPedido == null ? null : new Date(model.fechaPedido.toString());
        model.fechaUltimoAcceso = model.fechaUltimoAcceso == null ? null : new Date(model.fechaUltimoAcceso);
        model.created = model.created == null ? null : new Date(model.created);
        model.modified = model.modified == null ? null : new Date(model.modified);
        model.configuradoProveedor = model.configuradoProveedor == null ? null : new Date(model.configuradoProveedor);
        if (model.logsBurlington != null) {
            model.logsBurlington.forEach((item, index) => {
                item.date = item.date == null ? null : new Date(item.date);
            });
        }
        
        model.boolContenidoDigital = model.contenidoId != null;
    }

    getTiposLicencias(editorial: string): Promise<GetTiposLicenciaResponse> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<GetTiposLicenciaResponse>(`intranet/utils/getTiposLicencias/${editorial}`);
        return prom;
    }

    exportExcel(search: LicenciasContenidosSearch): Promise<any> {
        var url = "intranet/colegio/licencias-contenido/exportExcel";

        var apiFetcher = new ApiFetcher();
        var promise = apiFetcher.post<LicenciasContenidosSearch, ExportFileResult>(url, search)
            .then((result) => {
                downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return promise;
    }

    syncBurlington(licenciaContenidoId: number): Promise<BurlingtonOperationResult> {

        var url = `intranet/colegio/licencias-contenido/syncBurlington/${licenciaContenidoId}`;

        var apiFetcher = new ApiFetcher();
        var promise = apiFetcher.post<any, BurlingtonOperationResult>(url, null)
            .then((result) => {
                
                return result;
            });
        return promise;
    }

    deleteBurlington(email: string): Promise<BurlingtonOperationResult> {

        var url = `intranet/colegio/licencias-contenido/deleteBurlington/${encodeURIComponent(email)}`;

        var apiFetcher = new ApiFetcher();
        var promise = apiFetcher.post<any, BurlingtonOperationResult>(url, null)
            .then((result) => {

                return result;
            });
        return promise;
    }

    syncColegioBurlington(): Promise<BurlingtonOperationResult> {

        var url = `intranet/colegio/licencias-contenido/syncColegioBurlington`;

        var apiFetcher = new ApiFetcher();
        var promise = apiFetcher.post<any, BurlingtonOperationResult>(url, null)
            .then((result) => {

                return result;
            });
        return promise;
    }


}


export interface LicenciaContenidosEditModelEx extends LicenciaContenidosEditModel {

    nivelEducativoId?: number;

    boolContenidoDigital: boolean;
}