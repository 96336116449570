import React from 'react'; // we need this to make JSX compile
import { Link, NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { RouteHelper } from '../IntranetRouter';
import { Impersonate } from '../login/Impersonate';
import { Logoff } from '../login/Logoff';
import { TipoUsuario } from '../models/dataModels';
import { NotificationIcon } from '../notificaciones/NotificationIcon';
import { SHubBreadcrumb } from './base/Breadcrumb';
import { ClientContext } from './ClientContext';
import { I18n } from './I18n';

interface AuthenticatedMasterState {
    showImpersonation: boolean;
    cursoEscolarActivoId: number;
}


class AuthenticatedMasterComp extends React.Component<RouteComponentProps<any>, AuthenticatedMasterState> {


    constructor(props) {
        super(props);

        this.state = {
            showImpersonation: false,
            cursoEscolarActivoId: ClientContext.Current.cursoEscolarActivoId
        };

        document.title = ClientContext.Current.nombreColegio + " - Scholarum Hub";
    }

    private closeSidebar = () => {
        var sideMenu = document.getElementById("sidebarMenu");
        sideMenu.classList.remove('open');
    }

    private toggleSidebar = () => {
        var sideMenu = document.getElementById("sidebarMenu");
        if (sideMenu.classList.contains('open')) {
            sideMenu.classList.remove('open');
        }
        else {
            sideMenu.classList.add('open');
        }
    }

    public render() {

        var usuarioActualTipo = (ClientContext.Current.tipoUsuario || 0);

        return <React.Fragment key={this.state.cursoEscolarActivoId}>

            {this.state.showImpersonation &&
                <Impersonate onClose={() => this.setState({ showImpersonation: false })} />
            }
            <Row>
                <nav className="navbar navbar-expand-lg fixed-top" aria-label="Main navigation">
                    <div id="sidebarMenu" className="navbar-collapse offcanvas-collapse">
                        <nav onClick={() => this.closeSidebar()} className="sidebar col-izq collapse">
                            <div className="position-sticky" style={{ "width": "100%" }}>
                                <div className="logo-centro">
                                    <Link to={RouteHelper.home()} title={ClientContext.Current.nombreColegio}>
                                        <img src={ClientContext.Current.logoColegioUrl} className="img-fluid" alt={ClientContext.Current.nombreColegio} />
                                    </Link>
                                </div>

                                <div className="ciclo-lectivo">
                                    <p>
                                        Año académico
                                    </p>

                                    {(ClientContext.Current.cursosEscolares || []).length > 1 &&
                                        <form className="col-12">

                                            <select
                                                className="form-select form-select-sm"
                                                aria-label="curso-escolar"
                                                defaultValue={ClientContext.getCursoEscolarActivo()?.id}
                                                onChange={(evt) => {
                                                    var nuevoCursoEscolar = parseInt(evt.currentTarget.value);
                                                    ClientContext.setCursoEscolarActivo(nuevoCursoEscolar);
                                                    ClientContext.globalCache = [];

                                                    this.setState({ cursoEscolarActivoId: nuevoCursoEscolar });
                                                }}
                                            >
                                                {(ClientContext.Current.cursosEscolares || []).map((curso, index) => {
                                                    return <option key={index} value={curso.id}>{curso.nombre}</option>;
                                                })}
                                            </select>

                                        </form>
                                    }
                                    {(ClientContext.Current.cursosEscolares || []).length == 1 &&
                                        <span>
                                            <img src="/img/ic-calen.svg" alt="Curso escolar" className="ic-menu" />
                                            {ClientContext.Current.cursosEscolares[0].nombre}
                                        </span>
                                    }
                                </div>

                                <ul className="nav flex-column menu-izq">

                                    <li className="nav-item">
                                        <NavLink to={RouteHelper.home()} className="nav-link" activeClassName="active" exact={true}>
                                            <img src="/img/ic-mad.svg" alt="Mis accesos directos" className="ic-menu" />
                                            <span>Mis accesos directos</span>
                                        </NavLink>
                                    </li>

                                    {((usuarioActualTipo == TipoUsuario.Profesor) || (usuarioActualTipo == TipoUsuario.Alumno)) &&
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to={RouteHelper.listMyLicenses()}>
                                                <i className="bi bi-lock"></i> Mis licencias
                                            </NavLink>
                                        </li>
                                    }

                                    {(usuarioActualTipo == TipoUsuario.Profesor) &&
                                        <React.Fragment>
                                            <li className="nav-item">
                                                <NavLink to={RouteHelper.listMisClasesProfesor()} className="nav-link" activeClassName="active">
                                                    <img src="/img/ic-calen.svg" alt={I18n.Strings.menu.misClases} className="ic-menu" />
                                                    <span>{I18n.Strings.menu.misClases}</span>
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to={RouteHelper.listEstadisticasProfesor(ClientContext.Current.usuarioId)} className="nav-link" activeClassName="active">
                                                    <img src="/img/ic-est.svg" alt="Estadísticas" className="ic-menu" />
                                                    <span>Estadísticas</span>
                                                </NavLink>
                                            </li>
                                        </React.Fragment>
                                    }
                                </ul>

                                {(usuarioActualTipo == TipoUsuario.AdminGlobal) &&
                                    <React.Fragment>
                                        <p>Administración global</p>
                                        <ul className="nav flex-column menu-izq">

                                            <li className="nav-item">
                                                <NavLink to={RouteHelper.listContenidos()} className="nav-link" activeClassName="active">
                                                    <img src="/img/ic-mdc.svg" alt="Maestro de contenidos" className="ic-menu" />
                                                    Maestro de contenidos
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink to={RouteHelper.listAliasEditoriales()} className="nav-link" activeClassName="active">
                                                    <img src="/img/ic-ae.svg" alt="Alias editoriales" className="ic-menu" />
                                                    Alias editoriales
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink to={RouteHelper.reports()} className="nav-link" activeClassName="active">
                                                    <img src="/img/ic-ae.svg" alt="Informes" className="ic-menu" />
                                                    Informes
                                                </NavLink>
                                            </li>


                                        </ul>
                                    </React.Fragment>
                                }

                                {(usuarioActualTipo == TipoUsuario.AdminGlobal || usuarioActualTipo == TipoUsuario.AdminColegio) &&
                                    <React.Fragment>
                                        <p>Administración colegio</p>
                                        <ul className="nav flex-column menu-izq mb-5">

                                            <li className="nav-item">
                                                <NavLink to={RouteHelper.listEstadisticasAdmin()} className="nav-link" activeClassName="active">
                                                    <img src="/img/ic-est.svg" alt="Estadísticas" className="ic-menu" />
                                                    <span>Estadísticas</span>
                                                </NavLink>
                                            </li>

                                            {ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal &&
                                                <li className="nav-item">
                                                    <NavLink to={RouteHelper.cargaFicheros()} className="nav-link" activeClassName="active">
                                                        <img src="/img/ic-cdf.svg" alt="Carga de ficheros" className="ic-menu" />
                                                        <span>Carga de ficheros</span>
                                                    </NavLink>
                                                </li>
                                            }

                                            <li className="nav-item">
                                                <NavLink to={RouteHelper.listUsuariosColegio()} className="nav-link" activeClassName="active">
                                                    <img src="/img/ic-user.svg" alt="Usuarios" className="ic-menu" />
                                                    <span>Usuarios</span>
                                                </NavLink>
                                            </li>


                                            <li className="nav-item">
                                                <NavLink to={RouteHelper.listPedidosTienda()} className="nav-link" activeClassName="active">
                                                    <img src="/img/ic-ae.svg" alt="Informes" className="ic-menu" />
                                                    Pedidos tienda online
                                                </NavLink>
                                            </li>


                                            <li className="nav-item">
                                                <NavLink to={RouteHelper.listPedidosColegio()} className="nav-link" activeClassName="active">
                                                    <img src="/img/ic-ae.svg" alt="Pedidos colegio" className="ic-menu" />
                                                    Pedidos del colegio
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink to={RouteHelper.listDevolucionesColegio()} className="nav-link" activeClassName="active">
                                                    <img src="/img/ic-ae.svg" alt="Devoluciones colegio" className="ic-menu" />
                                                    Devoluciones del colegio
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink to={RouteHelper.listLicenciasColegio()} className="nav-link" activeClassName="active">
                                                    <img src="/img/ic-lic.svg" alt="Ícono" className="ic-menu" />
                                                    <span>Libros texto / Licencias</span>
                                                </NavLink>
                                            </li>

                                            {ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal &&
                                                <>
                                                    <li className="nav-item">
                                                        <NavLink to={RouteHelper.listContenidosColegio()} className="nav-link" activeClassName="active">
                                                            <img src="/img/ic-cont.svg" alt="Contenidos" className="ic-menu" />
                                                            <span> Contenidos</span>
                                                        </NavLink>
                                                    </li>

                                                    <li className="nav-item">
                                                        <NavLink to={RouteHelper.listContenidosPapelColegio()} className="nav-link" activeClassName="active">
                                                            <img src="/img/ic-cont.svg" alt="Contenidos papel" className="ic-menu" />
                                                            <span> Contenidos papel</span>
                                                        </NavLink>
                                                    </li>

                                                    <li className="nav-item">
                                                        <NavLink to={RouteHelper.listCursosEscolares()} className="nav-link" activeClassName="active">
                                                            <img src="/img/ic-calen.svg" alt="Curso escolar" className="ic-menu" />
                                                            <span>Curso escolar</span>
                                                        </NavLink>
                                                    </li>

                                                    <li className="nav-item">
                                                        <NavLink to={RouteHelper.listAccesosDirectos()} className="nav-link" activeClassName="active">
                                                            <img src="/img/ic-mad.svg" alt="Ícono" className="ic-menu" />
                                                            <span>Accesos directos</span>
                                                        </NavLink>
                                                    </li>
                                                </>
                                            }


                                        </ul>
                                    </React.Fragment>
                                }
                                <div style={{ height: "100px" }}>
                                </div>
                                {/*<div className="logo-s-hub">*/}

                                {/*    Desarrollado por s-hub*/}

                                {/*</div>*/}
                            </div>
                        </nav>
                    </div>
                </nav>

                <main className="area-central">
                    <div className="barra-sup">
                        <div className="row col-flex">
                            <div className="col-2 col-sm-2 col-md-1 d-lg-none d-xl-none">
                                <div className="btn-collapse">
                                    <button onClick={() => this.toggleSidebar()} className="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
                                        <i className="bi bi-list"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-10 col-sm-10 col-md-11 col-lg-12 col-xl-12">
                                <div className="row col-flex">
                                    <div className="col-4 col-md-4 col-xl-7">
                                        <div className="d-lg-none d-xl-none logo-movil">
                                            <img src={ClientContext.Current.logoColegioUrl} className="img-fluid" alt={ClientContext.Current.nombreColegio} />
                                        </div>
                                        <div className="d-sm-none d-lg-block">
                                            <h4 className="p-4 pt-2 pb-0">
                                                <strong>
                                                    {ClientContext.Current.nombreColegio}
                                                </strong>
                                            </h4>
                                        </div>
                                        {/*
                                        <div className="progreso-perfil d-none d-sm-block">
                                            <a href="#">Completa tu perfil</a>
                                            <Progress value={50} min={0} max={100}> 50% </Progress>
                                        </div>
                                        */}
                                    </div>

                                    <div className="col-8 col-md-8 col-xl-5">
                                        <div className="perfil me-3">
                                            {(ClientContext.Current.tipoUsuario == TipoUsuario.AdminColegio || ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal) &&
                                                <NotificationIcon />
                                            }

                                            <img src={ClientContext.Current.imagenPerfilUrl} className="img-perfil" />
                                            <UncontrolledDropdown>
                                                <DropdownToggle onClick={() => this.closeSidebar()} className="pointer d-block link-dark text-decoration-none dropdown-toggle" tag="a" caret>
                                                    <div className="nombre-usuario">
                                                        {ClientContext.Current.nombre}
                                                        {ClientContext.Current.estoyImpersonado &&
                                                            <span> (Impersonado) </span>
                                                        }
                                                    </div>
                                                </DropdownToggle>
                                                <DropdownMenu tag="ul" className="text-small">
                                                    {usuarioActualTipo == TipoUsuario.Alumno &&
                                                        <DropdownItem tag="li">
                                                            <NavLink className="dropdown-item" activeClassName="" to={RouteHelper.myProfileStudent()}>
                                                                {I18n.Strings.myProfile}
                                                            </NavLink>
                                                        </DropdownItem>}
                                                    {usuarioActualTipo == TipoUsuario.Profesor &&
                                                        <DropdownItem tag="li">
                                                            <NavLink className="dropdown-item" activeClassName="" to={RouteHelper.myProfileTeacher()}>
                                                                {I18n.Strings.myProfile}
                                                            </NavLink>
                                                        </DropdownItem>}

                                                    {(usuarioActualTipo == TipoUsuario.AdminColegio || usuarioActualTipo == TipoUsuario.AdminGlobal) &&
                                                        <DropdownItem tag="li">
                                                            <NavLink className="dropdown-item" activeClassName="" to={RouteHelper.myProfileAdmin()}>
                                                                {I18n.Strings.myProfile}
                                                            </NavLink>
                                                        </DropdownItem>
                                                    }

                                                    {(usuarioActualTipo == TipoUsuario.AdminColegio || usuarioActualTipo == TipoUsuario.AdminGlobal || ClientContext.Current.estoyImpersonado)
                                                        &&
                                                        <DropdownItem tag="li">
                                                            <a className="dropdown-item"
                                                                onClick={() => this.setState({ showImpersonation: !this.state.showImpersonation })}
                                                            >
                                                                {!ClientContext.Current.estoyImpersonado && "Emular usuario"}
                                                                {ClientContext.Current.estoyImpersonado && "Volver a mi usuario"}
                                                            </a>
                                                        </DropdownItem>
                                                    }

                                                    {(usuarioActualTipo == TipoUsuario.AdminColegio || usuarioActualTipo == TipoUsuario.AdminGlobal)
                                                        && <React.Fragment>
                                                            <DropdownItem tag="li">
                                                                <NavLink className="dropdown-item" to={RouteHelper.myProfileSchool()}>
                                                                    Datos del colegio
                                                                </NavLink>
                                                            </DropdownItem>
                                                        </React.Fragment>
                                                    }

                                                    {usuarioActualTipo == TipoUsuario.AdminGlobal &&
                                                        <React.Fragment>
                                                            <DropdownItem divider={true} />

                                                            <DropdownItem tag="li">
                                                                <a className="dropdown-item" href="/AdminUtils/Exceptions" target="_blank">
                                                                    Registro de errores
                                                                </a>
                                                            </DropdownItem>

                                                            <DropdownItem tag="li">
                                                                <a className="dropdown-item" href="/AdminUtils/hangfire" target="_blank">
                                                                    Panel de jobs
                                                                </a>
                                                            </DropdownItem>
                                                        </React.Fragment>
                                                    }

                                                    {!ClientContext.Current.estoyImpersonado && <React.Fragment>
                                                        <DropdownItem divider={true} />
                                                        <DropdownItem tag="li">
                                                            <Logoff className="dropdown-item" />
                                                        </DropdownItem>
                                                    </React.Fragment>}

                                                </DropdownMenu>
                                            </UncontrolledDropdown>

                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="contenidos">
                        <SHubBreadcrumb />
                        {this.props.children}
                    </div>

                    <div className="logo-i-hub d-none d-lg-block">
                        <div className="row" style={{ margin: "5px 10px 0px" }}>
                            <div className="col-md-6">
                                <div className="">
                                    Proyecto financiado por la Dirección General del Libro y Fomento de la Lectura. Ministerio de Cultura y Deporte
                                </div>

                                <img src="/img/logo-mcd.jpg" className="img-fluid mt-2" alt="Ministerio de Cultura y Deporte" />

                                <img src="/img/logo-plan.png" className="img-fluid mt-2" alt="Plan de Recuperación" />

                                <img src="/img/logo-funded-ue.png" className="img-fluid mt-2" alt="Financiado Unión Europea" />
                            </div>
                            <div className="col-md-6">
                                <img src="/img/logo-fondo-ue.png" style={{ float: 'left', marginRight: 5 }} className="img-fluid mt-2" alt="Fondo Europeo de desarrollo regional" />

                                <p style={{ textAlign: 'justify', lineHeight: "13px", fontSize: "10px" }}>
                                    SCHOLARUM DIGITAL,SL, ha sido beneficiaria del Fondo Europeo de Desarrollo Regional, cuyo objetivo es mejorar el uso y la calidad de las tecnologías de la información y de las comunicaciones y el acceso a las mismas, y gracias al que ha podido implantar las siguientes soluciones: Soluciones de comercio electrónico. Esta acción ha tenido lugar durante el año 2022. Para ello ha contado con el apoyo del programa TICCámaras de la Cámara de Madrid
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*
                    <Row>
                        <Col>
                            <NavLink to={RouteHelper.listUsuariosColegio()}>Usuarios</NavLink>
                        </Col>
                        <Col>
                            <NavLink to={RouteHelper.listPertenencias()}>Usuarios-Grupos</NavLink>
                        </Col>
                        <Col>
                            <NavLink to={RouteHelper.listCursosEscolares()}>Curso Escolar</NavLink>
                        </Col>
                        <Col>
                            <NavLink to={RouteHelper.listLicenciasColegio()}>Licencias colegio</NavLink>
                        </Col>
                        <Col>
                            <NavLink to={RouteHelper.listContenidos()}>Contenidos</NavLink>
                        </Col>
                        <Col>
                            <NavLink to={RouteHelper.listCredenciales()}>Credenciales</NavLink>
                        </Col>
                        <Col>
                            <NavLink to={RouteHelper.listNivelesContenidos()}>Niveles-Contenidos</NavLink>
                        </Col>
                    </Row>
                    */}
                </main>
            </Row >
        </React.Fragment >;
    }
}

export const AuthenticatedMaster = withRouter(AuthenticatedMasterComp);