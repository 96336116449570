import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import nameof from "ts-nameof.macro";
import { RouteHelper } from "../IntranetRouter";
import { LicenciaContenidosEditTabs } from "../licenciasColegio/Edit";
import { MiPerfilColegioService } from "../miPerfil/colegio/MiPerfilColegioService";
import { LineaPedidoTiendaListItem, MatriculaEditModel, PedidoTiendaDetailResult, TipoProductoTienda } from "../models/dataModels";
import { AlertsService } from "../utils/AlertsService";
import { IServiceForEdit } from "../utils/base/BaseServices";
import { NavigationHelper } from "../utils/base/Breadcrumb";
import { EditPageBase } from "../utils/base/EditPageBase";
import { GenericEditState } from "../utils/base/GenericEditState";
import { ModelValidation } from "../utils/base/ModelValidation";
import { I18n } from "../utils/I18n";
import { SyncIcon, UserIcon } from "../utils/Icons";
import { toCamelCase } from "../utils/ValuesFormatter";
import { PedidosTiendaService } from "./PedidosTiendaService";



export class EditComp extends EditPageBase<PedidoTiendaDetailResult> {

    protected _setPageTitleForNew(): string {
        return "N/A"
    }

    protected _setPageTitleForExisting(editModel: PedidoTiendaDetailResult): string {
        return `Ver pedido ${editModel.id}`;
    }

    protected _createApiService(): IServiceForEdit<PedidoTiendaDetailResult> {
        return new PedidosTiendaService();
    }

    protected _generateForm(): JSX.Element {
        return <React.Fragment>
            <Row>
                <Col md={2}>

                    <FormGroup>
                        <Label for={nameof(this.state.editModel.id)}>
                            {I18n.Strings.pedidos.numPedido}
                        </Label>

                        <Input
                            readOnly={true}
                            id={nameof(this.state.editModel.id)}
                            value={this.state.editModel.id}
                        />
                    </FormGroup>

                </Col>
                <Col md={3}>

                    <FormGroup>
                        <Label for={nameof(this.state.editModel.fechaPedido)}>
                            {I18n.Strings.pedidos.fechaCompra}
                        </Label>

                        <Input
                            readOnly={true}
                            id={nameof(this.state.editModel.fechaPedido)}
                            value={I18n.Strings.formatDate(this.state.editModel.fechaPedido)}
                        />
                    </FormGroup>

                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.nombreCliente)}>
                            {I18n.Strings.pedidos.cliente}
                        </Label>

                        <Input
                            readOnly={true}
                            id={nameof(this.state.editModel.nombreCliente)}
                            value={this.state.editModel.nombreCliente}
                        />
                    </FormGroup>
                </Col>

                <Col md={2}>
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.fechaDeProcesoEnJob)}>
                            {I18n.Strings.pedidos.fechaDeProcesoEnJob}
                        </Label>

                        <Input
                            readOnly={true}
                            value={I18n.Strings.formatDate(this.state.editModel.fechaDeProcesoEnJob)}
                        />
                    </FormGroup>
                </Col>

                <Col md={2}>
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.estadoPedido)}>
                            {I18n.Strings.pedidos.estado}
                        </Label>

                        <Input
                            readOnly={true}
                            value={I18n.Strings.getEnumText(`EstadoPedido.${this.state.editModel.estadoPedido}`)}
                        />
                    </FormGroup>
                </Col>

                <Col md={2}>
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.estadoPedidoScholarum)}>
                            {I18n.Strings.pedidos.estadoScholarum}
                        </Label>

                        <Input
                            readOnly={true}
                            value={I18n.Strings.getEnumText(`EstadoPedidoScholarum.${this.state.editModel.estadoPedidoScholarum}`)}
                        />
                    </FormGroup>
                </Col>

                <Col md={5}>
                    <FormGroup>
                        <Label>
                            {I18n.Strings.pedidos.descuentoDescrip}
                        </Label>

                        <Input
                            readOnly={true}
                            value={`${this.state.editModel.descripDescuento || "N/A"} - ${this.state.editModel.cupones || "N/A"}`}
                        />
                    </FormGroup>
                </Col>

                <Col md={2}>
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.descuento)}>
                            {I18n.Strings.pedidos.descuento}
                        </Label>

                        <Input
                            readOnly={true}
                            value={this.state.editModel.descuento || "N/A"}
                        />
                    </FormGroup>
                </Col>

            </Row>

            {this._pintarTablaLicencias(
                this.state.editModel.items.filter(i => i.tipoProducto == TipoProductoTienda.License || i.tipoProducto == TipoProductoTienda.Virtual),
                "Compras digitales")
            }

            {this._pintarTablaLicencias(
                this.state.editModel.items.filter(i => i.tipoProducto == TipoProductoTienda.Simple),
                "Compras papel")
            }

            <div>
                <h4>Otras licencias asociadas</h4>
                {this.state.editModel.licenciasExtra.length == 0 &&
                    <React.Fragment>
                        No hay elementos
                    </React.Fragment>
                }
                {this.state.editModel.licenciasExtra.length != 0 &&
                    <table className="table table-striped table-hover table-sm">
                        <thead>

                            <tr>
                                <th>{I18n.Strings.pedidos.sku}</th>
                                <th>{I18n.Strings.pedidos.producto}</th>
                                <th>{I18n.Strings.pedidos.licencias}</th>
                                <td>{toCamelCase(I18n.Strings.colegioUsuarios.usuarioAlumno)}</td>
                            </tr>

                        </thead>
                        <tbody>
                            {this.state.editModel.licenciasExtra.map((item, index) => {

                                return <tr key={index}>
                                    <td>{item.isbn}</td>
                                    <td>{item.contenido}</td>
                                    <td>
                                        <Link className="mr-2"
                                            to={RouteHelper.editLicenciasColegio(item.licenciaContenidoId, item.usuarioId, null, LicenciaContenidosEditTabs.Datos)}
                                        >
                                            {item.usuario}
                                        </Link>
                                    </td>
                                    <td>
                                        <a title="Ver usuario" href={RouteHelper.editUsuarioColegio(item.usuarioId)} target="_blank">
                                            <UserIcon />
                                        </a>
                                    </td>
                                </tr>;
                            })}
                        </tbody>
                    </table>
                }
            </div>

        </React.Fragment>;
    }

    private _pintarTablaLicencias(items: LineaPedidoTiendaListItem[], titulo: string): JSX.Element {

        return <>
            <h4 className="mt-4">{titulo}</h4>
            {items.length == 0 &&
                <React.Fragment>
                    No hay elementos
                </React.Fragment>
            }
            {items.length != 0 &&
                <table className="table table-striped table-hover table-sm">
                    <thead>

                        <tr>
                            <th>{I18n.Strings.pedidos.sku}</th>
                            <th>{I18n.Strings.pedidos.producto}</th>
                            <th>{I18n.Strings.pedidos.tipo}</th>
                            <th>{I18n.Strings.pedidos.cantPedida}</th>
                            <th>{I18n.Strings.pedidos.cantDevuelta}</th>
                            <th>{I18n.Strings.pedidos.licencias}</th>
                        </tr>

                    </thead>
                    <tbody>
                        {items.map((item, index) => {

                            return <tr key={index}>
                                <td>{item.sku}</td>
                                <td>{item.producto}</td>
                                <td>{I18n.Strings.getEnumText(`TipoProductoTienda.${item.tipoProducto}`)}</td>
                                <td>{item.cantidadPedida}</td>
                                <td>{item.cantidadDevuelta}</td>
                                <td>{this._pintarLicencias(item)}</td>
                            </tr>;
                        })}
                    </tbody>
                </table>
            }
        </>;
    }


    private _pintarLicencias(item: LineaPedidoTiendaListItem): JSX.Element {
        if ((item.licencias || "").length == 0) {
            return <span>N/A</span>;
        }
        else {
            let licencias = item.licencias.split(",");
            return <React.Fragment>
                {licencias.map((lic, index) => {

                    var partes = lic.split("#");

                    return <div>
                        <Link className="mr-2" to={RouteHelper.editLicenciasColegio(partes[0], null, null, LicenciaContenidosEditTabs.Datos)}>
                            {partes[1]}
                        </Link>
                        {' '}
                        <a title="Ver usuario" href={RouteHelper.editUsuarioColegio(partes[2])} target="_blank">
                            <UserIcon />
                        </a>
                    </div>;
                })}
            </React.Fragment>;
        }

    }

    protected _extraButtons() {

        return <Button color="secondary" className="btn-rounded"
            onClick={() => {

                this.setState({ showLoading: true }, () => {

                    var service = new MiPerfilColegioService();
                    var prom = service.syncPedidosColegio(null, this.state.editModel.id.toString(), null, false);

                    prom.then(ret => {
                        this.setState({ showLoading: false }, () => {
                            AlertsService.showSuccessMessage(I18n.Strings.notificaciones.procesoEnCola);
                        });
                    }).catch(err => this.setState({ showLoading: false }, () => AlertsService.showError(err)));
                });

            }}>
            <SyncIcon />
            {I18n.Strings.pedidos.syncEstePedido}
        </Button>;
    }

    protected updateBreadCrumb(model: PedidoTiendaDetailResult) {
        var items = [
            {
                text: I18n.Strings.pedidos.title,
                link: RouteHelper.listPedidosTienda()
            },
            {
                text: this._setPageTitleForExisting(model),
                link: null
            }
        ];

        NavigationHelper.setBreadcrumbItems(items);

    }

    protected _getEditUrl(id: number): string {
        return RouteHelper.detallePedidoTienda(id);
    }

    protected _getListUrl(): string {
        return RouteHelper.listPedidosTienda();
    }

    protected _createStateForNew(): Promise<GenericEditState<PedidoTiendaDetailResult>> {


        return null;
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        return validation;
    }
}

export const Edit = withRouter(EditComp);